define("garage/mirage/fixtures/vehicletype", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "vehicletype",
    "attributes": {
      "name": "Car",
      "capacityConfigs": [[{ "unitId": 1, "count": 3 }]],
      "attributes": [{ "id": 1 }, { "id": 2 }]
    }
  }, {
    "id": 2, type: "vehicletype",
    "attributes": {
      "name": "Minibus",
      "capacityConfigs": [[{ "unitId": 1, "count": 8 }, { "unitId": 2, "count": 2 }, { "unitId": 2, "count": 5 }]],
      "attributes": [{ "id": 2 }]
    }
  }, {
    "id": 3, type: "vehicletype",
    "attributes": {
      "name": "Bus",
      "capacityConfigs": [[{ "unitId": 1, "count": 18 }, { "unitId": 2, "count": 22 }]],
      "attributes": []
    }
  }];
});