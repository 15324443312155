define('garage/locales/en/translations', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        weekday: {
            Monday: 'Monday',
            Tuesday: 'Tuesday',
            Wednesday: 'Wednesday',
            Thursday: 'Thursday',
            Friday: 'Friday',
            Saturday: 'Saturday',
            Sunday: 'Sunday'
        },

        languages: {
            fi: 'Finnish',
            en: 'English',
            sv: 'Swedish',
            de: 'German'
        },

        dropdown: {
            select: 'Select...'
        },

        toggle: {
            capacity: 'Use vehicle specific values',
            showInactive: 'Show expired and not yet started shifts.',
            Alcolock: 'Alcolock',
            'Easy access': 'Easy access'
        },

        // ---------------

        button: {
            Delete: 'Delete',
            Copy: 'Copy',
            New: 'New',
            Revert: 'Revert',
            Save: 'Save',
            SaveAsType: 'Save as type',
            SaveAsGroup: 'Save as group',
            SaveAll: 'Save all',
            Join: 'Join',
            Add: 'Add',
            Edit: 'Edit',
            Remove: 'Remove',
            Cancel: 'Cancel',
            Login: 'Login',
            Logout: 'Logout',
            CopyToVehicle: 'Copy to vehicle',
            GenQRCode: 'Gen QR-Code'
        },

        capacity: {
            wheelchair: 'Wheelchair',
            ambulatory: 'Ambulatory',
            luggage: 'Luggage',
            walker: 'Walker',
            dog: 'Dog'
        },

        tab: {
            Vehicle: 'Vehicle',
            Capacity: 'Capacities',
            Attributes: 'Attributes',
            Shift: 'Shift',
            Garage: 'Garage',
            Terminal: 'Terminal',

            Info: 'Info',
            Vehicles: 'Vehicles',
            Operators: 'Operators',
            Drivers: 'Drivers',
            VehicleReports: 'Route Report',
            OperatorReports: 'Vehicle Report',
            Settings: 'Settings',

            Users: 'Users',
            UserManagement: 'User management',
            OtherSettings: 'Some other settings',

            LicenseSettings: 'License Settings'
        },

        form: {
            label: {
                Name: 'Name',
                Operator: 'Operator',
                Fleet: 'Fleet',
                CallId: 'Vehicle number',
                ExternalId: 'Ext vehicle number',
                PaymentTerminalId: 'Payment terminal id',
                Description: 'Description',
                DispatchParameters: 'Dispatch',
                DispatchArea: 'Area',
                DispatchPriority: 'Priority',
                LicenceNumber: 'Licence number',
                Phone: 'Phone',
                PriceFactor: 'Price Factor',
                EmissionFactor: 'Emission Factor',
                ServiceFactor: 'Service Factor',
                VehicleType: 'Vehicle type',
                Attributes: 'Attributes',
                Capacities: 'Add new capacity',
                ExistingCapacities: 'Vehicle capacities',
                ShiftGroups: 'Shift groups',
                Customization: 'Customization',
                Username: 'Username',
                Password: 'Password',
                ShiftTemplates: 'Shift templates',
                ShiftTemplateInterval: 'Template timespan',
                UseShift: 'Use shift',

                UserEmail: 'User Email',
                UserPassword: 'User Password',
                CompanyName: 'Company Name',
                CompanyAddress: 'Company Address',
                ContactName: 'Contact Name',
                ContactPhone: 'Contact Phone',
                ContactEmail: 'Contact Email',
                BusinessId: 'Business Id',
                OperatorInfo: 'Info (visible for admins only)',
                OperatorExternalId: 'External Id',
                Iban: 'Bank Account (IBAN)',
                Bic: 'Bank identifier (BIC)',

                Email: 'Email',
                PinCode: 'Pin Code',
                DriverExternalId: 'External id',
                LicenseAccepted: 'License agreement accepted',
                Status: 'Status',
                Role: 'Role',
                Permissions: 'Permissions',

                AddUser: 'Add new user',
                AllUsers: 'All users',
                Verified: 'Verified',
                VerifiedText: 'Leave empty if user should verify through received email',
                Unlink: 'Unlink',
                Contract: 'Contract',

                driver: {
                    status: {
                        active: 'Active',
                        inactive: 'Inactive'
                    }
                }
            },
            help: {
                DispatchArea: 'Comma separated list of area ids. e.g. Area1, Area2. Empty value=All areas.',
                DispatchPriority: 'Higher the value then better the change to get a job offer. 0=no job offers, 100=default',
                PriceFactor: 'Price multiplier for some specific dispatch strategies usage.',
                EmissionFactor: 'Emission Factor',
                ServiceFactor: 'Service Factor',
                UseShift: 'Trips are dispatched only within shift. By default: trips are dispatched always.'
            },
            text: {
                Passengers: 'Passengers',
                Wheelchair: 'Wheelchair',
                WalkingAid: 'Walking aid',
                SafetySeat: 'Safety seat',
                Alcolock: 'Alcolock',
                AllAreas: 'All areas'
            },
            errors: {
                'Value is required': 'This value cannot be empty.'
            },
            capacities: {
                ambulatory: 'Ambulatory',
                wheelchair: 'Wheelchair',
                luggage: 'Luggage',
                'assistive-device': 'Assistive Device',
                stretcher: 'Stretcher',
                'front-seat': 'Front Seat'
            },
            attributes: {
                'test-vehicle': 'Test vehicle',
                bus: 'Bus',
                'station-wagon': 'Station wagon',
                sedan: 'Sedan',
                'eco-friendly': 'Environmentally friendly',
                paratransit: 'Paratransit',
                lowrider: 'Lowrider',
                'pet-friendly': 'Pet friendly',
                'non-smoking': 'Non smoking',
                stretcher: 'Stretcher',
                stairclimber: 'Stairclimber',
                'front-seat': 'Front seat',
                'wheelchair-lift': 'Wheelchair lift',
                'multiple-wheelchairs': 'Multiple wheelchairs',
                'electric-wheelchair': 'Electric wheelchair',
                alcometer: 'Alcometer'
            },
            placeholder: {
                lookup: 'lookup...',
                alias: 'alias',
                filter: 'filter',
                searchAndAdd: 'search and add',
                noUser: 'no user'
            }
        },

        message: {
            title: {
                success: 'Success',
                failure: 'Failure',
                saving: 'Saving...'
            },
            text: {
                wait: 'Please wait!',

                vehicle: {
                    delete: {
                        success: 'Vehicle was successfully deleted.',
                        failure: 'Vehicle deletion failed.'
                    },
                    save: {
                        failure: 'Saving vehicle failed.'
                    },
                    new: {
                        failure: 'Creating a new vehicle failed.'
                    },
                    copy: {
                        failure: 'Copying a vehicle failed.'
                    }
                },
                shift: {
                    new: {
                        failure: 'Creating a new shift failed.'
                    }
                },
                operator: {
                    delete: {
                        success: 'Operator was successfully deleted.',
                        failure: 'Operator deletion failed.'
                    },
                    save: {
                        failure: 'Saving operator failed.'
                    },
                    new: {
                        failure: 'Creating a new operator failed.'
                    },
                    copy: {
                        failure: 'Copying a operator failed.'
                    }
                },
                driver: {
                    delete: {
                        success: 'Driver was successfully deleted.',
                        failure: 'Driver deletion failed.'
                    },
                    save: {
                        failure: 'Saving driver failed.',
                        operatorMandatory: 'Operator needs to be set.'
                    },
                    new: {
                        failure: 'Creating a new driver failed.'
                    },
                    copy: {
                        failure: 'Copying a driver failed.'
                    }
                },
                user: {
                    save: {
                        success: 'User is successfully saved.',
                        failure: 'Saving user failed.'
                    }
                }
            }
        },

        dialog: {
            button: {
                yes: 'Yes',
                no: 'No',
                close: 'Close'
            },
            saved: {
                title: 'Saved',
                text: {
                    success: 'Saved successfully.',
                    withErrors: 'Encountered the following errors while saving data: {{errors}}',
                    driverToOperatorError: '<br>-<b>{{name}}</b>: {{error}}',
                    driver: 'Succeeded for drivers: {{success}} (total: {{totalSuccess}})<br>Failed for drivers: {{failed}}',
                    vehicle: 'Succeeded for vehicles: {{success}} (total: {{totalSuccess}})<br>Failed for vehicles: {{failed}}',
                    operator: 'Succeeded for operators: {{success}} (total: {{totalSuccess}})<br>Failed for operators: {{failed}}'
                }
            },
            delete: {
                title: 'Delete {{name}}',
                text: 'Are you sure?'
            },
            revert: {
                title: 'Discard all changes',
                text: 'Are you sure?'
            },
            shiftCopy: {
                copyTitle: 'Copy shift template'
            },
            saveLicense: {
                title: 'Save license in language {{language}}',
                text: 'Are you sure you want to save?'
            },
            changeLangNoSave: {
                title: 'Exit without saving',
                text: 'Are you sure you want to switch language? Unsaved changes will be lost.'
            }
        },

        messages: {
            loginFailed: 'Login failed.',
            vehicle: {
                'data.attributes.name': 'Name incorrect',
                'data.attributes.number': 'Vehicle number incorrect'
            },
            shiftTpl: {
                'overlapping.shift.template': 'Overlapping shift template',
                'no.weekdays.interval': 'Interval too small for the selected days and times',
                'data.attributes.startTime': 'Start time incorrect',
                'data.attributes.endTime': 'End time incorrect',
                'data.attributes.startDay': 'Start day must be given',
                'data.attributes.endDay': 'End day must be given',
                overNightShiftCopyError: 'Copying over night shift templates is currently not supported',
                vehicleHasNoUseShift: "Vehicle doesn't use shifts"
            }
        },

        table: {
            shift: {
                columns: {
                    startDay: 'Start day',
                    startTime: 'Start time',
                    endDay: 'End day',
                    endTime: 'End time',
                    from: 'From',
                    to: 'To',
                    group: 'Group',
                    startTimeClock: 'Start time',
                    endTimeClock: 'End time',
                    startTimeDate: 'From',
                    endTimeDate: 'To'
                }
            }
        },

        import: {
            operator: {
                title: 'Operator import',
                unsaved: 'New or updated operators',
                instructions: 'Drag and Drop <b><i>mbr-operator*.tsv</i></b> file here'
            },
            vehicle: {
                title: 'Vehicle import',
                unsaved: 'New or updated vehicles',
                instructions: 'Drag and Drop <b><i>mbr-vehicle*.tsv</i></b> AND <b><i>mbr-capacity*.tsv</i></b> files OR vehicles <b><i>*.json</i></b> file here'
            },
            button: 'Import',
            save: 'Save all',
            clear: 'Clear imports'
        },

        role: {
            super: 'Super admin',
            admin: 'Admin',
            operator: 'Operator'
        },

        report: {
            noshow: 'Hukka-ajo',
            genReport: 'Generate report',
            routeId: 'Route number',
            externalRouteId: 'External id',
            vehicleNumber: 'Vehicle number',
            vehicleName: 'Vehicle name',
            driver: 'Driver',
            date: 'Date',
            startTime: 'Start',
            endTime: 'End',
            startLocation: 'From',
            endLocation: 'To',
            noResults: 'No results',
            noVehicles: '-- No vehicles --',
            routes: 'Routes',
            distance: 'Distance',
            price: {
                total: 'Price',
                excess: 'Excesses',
                extraFees: 'Extra fees',
                sum: 'Total Price',
                vat: 'VAT',
                vatType: 'VAT-%',
                waitFee: 'Wait fee',
                transitFee: 'Transit fee'
            }
        },

        etc: {
            license: {
                createdLicenses: 'You have created licenses for the following languages: {{languages}}.',
                noCreatedLicenses: 'No licenses created. Please select a language to create one.'
            }
        }
    };
});