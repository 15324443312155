define('garage/location/model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(Ember.Copyable, {
        street: _emberData.default.attr('string'),
        streetNumber: _emberData.default.attr('string'),
        door: _emberData.default.attr('string'),
        city: _emberData.default.attr('string'),
        province: _emberData.default.attr('string'),
        zipcode: _emberData.default.attr('string'),
        country: _emberData.default.attr('string'),
        alias: _emberData.default.attr('string'),
        lat: _emberData.default.attr('number'),
        lng: _emberData.default.attr('number'),
        info: _emberData.default.attr('string'),

        nameAndNumber: Ember.computed('street', 'streetNumber', 'city', function () {
            // @todo should be locale specific
            return this.get('street') + ' ' + (this.get('streetNumber') || '');
        }),

        nameNumberCity: Ember.computed('street', 'streetNumber', 'city', function () {
            // @todo should be locale specific
            return this.get('street') + ' ' + (this.get('streetNumber') || '') + ' (' + this.get('city') + ')';
        }),

        cityNameNumber: Ember.computed('street', 'streetNumber', 'city', function () {
            // @todo should be locale specific
            return '(' + this.get('city') + ') ' + this.get('street') + ' ' + (this.get('streetNumber') || '');
        }),

        copy: function copy() {
            this.get('store').createRecord('location', {
                street: this.get('street'),
                streetNumber: this.get('streetNumber'),
                door: this.get('door'),
                city: this.get('city'),
                province: this.get('province'),
                zipcode: this.get('zipcode'),
                country: this.get('country'),
                alias: this.get('alias'),
                lat: this.get('lat'),
                lng: this.get('lng'),
                info: this.get('info')
            });
        }
    });
});