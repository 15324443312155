define("garage/mirage/fixtures/fleets", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "fleet",
    "attributes": {
      "name": "Default"
    }
  }];
});