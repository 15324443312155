define('garage/components/vinka-table/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        i18n: Ember.inject.service(),

        output: Ember.A([]),

        sorters: Ember.A([]),

        days: null,

        didReceiveAttrs: function didReceiveAttrs() {
            this.resetSelection();
        },
        init: function init() {
            var _this = this;

            this._super.apply(this, arguments);
            this.days = {};

            // Translate all titles.
            var name = this.get('name');
            if (name) {
                this.get('columns').forEach(function (col) {
                    Ember.set(col, 'title', _this.get('i18n').t(name + '.' + col.name).string || 'Translation missing: ' + name + '.' + col.name);
                });
            }

            // Construct an object of localized days and their order numbers for sorting.
            var days = {};
            ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].forEach(function (day, index) {
                days[_this.get('i18n').t('weekday.' + day).string] = index;
            });
            this.set('days', days);

            // Create default sorters.
            var defaultSorting = {};
            this.get('columns').forEach(function (col) {
                if (col.defaultSort !== undefined && col.defaultSort !== null) {
                    defaultSorting[col.defaultSort] = col;
                }
            });
            var sorters = Ember.A([]);
            Object.keys(defaultSorting).forEach(function (i) {
                sorters.push(_this.makeSorter(1, defaultSorting[i]));
            });
            this.set('sorters', sorters);

            // Fill in output data from source.
            this.sort();
        },


        /**
         * Sort the content of the table and copy it to the version used by the template.
         */
        sort: function sort() {
            var _this2 = this;

            var data = this.get('data');
            var output = Ember.A([]);

            data.forEach(function (item) {
                return output.push(item);
            });
            output.sort(function (a, b) {

                // Go through the list of sorting functions and apply until a difference is found.
                var sorters = _this2.get('sorters');
                for (var i = 0; i < sorters.length; i++) {
                    var fn = sorters.objectAt(i).fn;
                    var a1 = a[fn.column],
                        b1 = b[fn.column];
                    var res = fn(a1, b1);
                    if (res < 0 || res > 0) {
                        return res;
                    }
                }

                return 0;
            });

            this.set('output', output);
        },


        /**
         * Generate a sorting function for a column.
         *
         * @param dir Either +1 or -1 for ascending or descending order.
         * @oaram column A column description for the table.
         *
         * @return A sorting function with extra field `column` containing name of the target column.
         */
        makeSorter: function makeSorter(dir, column) {

            var fn = null;
            var days = this.get('days');

            switch (column.sortAs) {

                case 'day':
                    fn = function fn(a, b) {
                        if (a === b) {
                            return 0;
                        } else if (a === null || days[a] > days[b]) {
                            return dir;
                        }
                        return -dir;
                    };
                    break;

                case 'time': // At the moment time and date format can be sorted as a string.
                case 'date':
                case 'string':
                    fn = function fn(a, b) {
                        if (a === b) {
                            return 0;
                        } else if (a === null || a > b) {
                            return dir;
                        }
                        return -dir;
                    };
                    break;

                default:
                    console.log('Invalid sorting criteria:', column.sortAs);
                    return null;
            }

            fn.column = column.name;

            return { fn: fn, direction: dir, type: column.sortAs };
        },


        /**
         * Follow original data and update table content, if changed.
         */
        dataChanged: Ember.observer('data', function () {
            this.sort();
        }),

        resetSelection: function resetSelection() {
            this.get('data').forEach(function (line) {
                var styles = line.get('style');
                styles = styles && styles.split ? styles.split(' ') : [];

                if (styles.indexOf('table-active') >= 0) {
                    delete styles[styles.indexOf('table-active')];
                }

                line.set('style', styles.join(' '));
            });
        },


        /**
         * Mark the selected line with style `table-active`.
         */
        select: function select(selected) {
            this.get('data').forEach(function (line) {

                if (!line.get) {
                    // Non-Ember object.
                    return;
                }

                var styles = line.get('style');
                styles = styles && styles.split ? styles.split(' ') : [];

                if (styles.indexOf('table-active') >= 0) {
                    delete styles[styles.indexOf('table-active')];
                }
                if (line.id === selected.id) {
                    styles.push('table-active');
                }

                line.set('style', styles.join(' '));
            });
        },


        actions: {
            onTableClick: function onTableClick(line) {
                if (this.get('selectable')) {
                    this.select(line);
                    var onTableClick = this.get('onTableClick');
                    if (onTableClick) {
                        onTableClick(line);
                    }
                }
            },


            /**
             * Delete sorter function for the column and re-insert it to the front as ascending sorter.
             */
            sortCol: function sortCol(index) {
                var sorters = this.get('sorters');
                var column = this.get('columns').objectAt(index);
                var filterExists = sorters.find(function (s) {
                    return s.fn.column === column.name;
                });
                var dir = filterExists ? filterExists.direction * -1 : 1;
                sorters = sorters.filter(function (s) {
                    return s.fn.column !== column.name;
                });

                var fn = this.makeSorter(dir, column);
                if (fn) {
                    sorters.splice(0, 0, fn);
                }
                this.set('sorters', sorters);
                this.sort();
            }
        }
    });
});