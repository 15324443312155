define("garage/mirage/fixtures/operators", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "operator",
    "attributes": {
      "name": "Operator 1"
    }
  }, {
    "id": 2, type: "operator",
    "attributes": {
      "name": "Operator 2"
    }
  }];
});