define('garage/operator/route', ['exports', 'garage/config/environment', 'garage/mixins/restricted-route-mixin', 'garage/helpers/permission-utils'], function (exports, _environment, _restrictedRouteMixin, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NEW_PREFIX = 'new';

    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.operator, _permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            operators: [],
            selected: null,
            canImport: false,
            hasDirty: false,
            savingAll: false,
            search: ''
        }),

        model: function model() {
            var _this = this;

            this.set('viewData.canImport', _environment.default.APP.import.enabled); // enable import based on configuration
            return this.get('store').findAll('operator', { reload: true }).then(function () {
                return _this.loadOperatorsToViewData().then(function () {
                    return _this.get('viewData');
                });
            });
        },
        loadOperatorsToViewData: function loadOperatorsToViewData() {
            var _this2 = this;

            return Promise.resolve(this.get('store').peekAll('operator')).then(function (result) {
                var operators = result.sortBy('companyName');
                _this2.set('viewData.operators', operators);
                _this2.set('viewData.hasDirty', operators.any(function (i) {
                    return i.get('hasDirtyAttributes');
                }));

                return operators;
            });
        },
        getOperatorByBusiness: function getOperatorByBusiness(businessId) {
            return businessId ? this.get('store').peekAll('operator').find(function (i) {
                return '' + businessId === '' + i.get('businessId');
            }) : null;
        },
        draftOperator: function draftOperator() {
            var operator = this.get('store').createRecord('operator');
            operator.set('id', this.getNextNewId());
            operator.set('companyName', 'New Operator');

            return operator;
        },
        addOperator: function addOperator() {
            var operator = this.get('store').createRecord('operator');
            operator.set('id', this.getNextNewId());
            operator.set('companyName', 'New Operator');

            return operator;
        },
        saveAll: function saveAll() {
            var _this3 = this;

            var unsavedOperators = this.get('store').peekAll('operator').filterBy('hasDirtyAttributes', true);

            var success = [];
            var failed = [];
            var chain = Promise.resolve();
            this.set('viewData.savingAll', true);

            var _loop = function _loop(i) {
                var operator = unsavedOperators[i];
                if (operator.get('id') && operator.get('id').startsWith(NEW_PREFIX)) {
                    operator.set('id', null);
                }
                chain = chain.then(function () {
                    return operator.save().then(function () {
                        success.push(operator.get('id'));
                    }).catch(function (e) {
                        failed.push('\n\r' + operator.get('id') + ' (' + e.errors[0].title + ')');
                    }).catch(function (e) {
                        failed.push('\n\r' + operator.get('id') + '(' + e.message + ')');
                    });
                });
            };

            for (var i = 0; i < unsavedOperators.length; i++) {
                _loop(i);
            }
            chain.then(function () {
                _this3.set('viewData.savingAll', false);
                var title = _this3.get('i18n').t('dialog.saved.title');
                var text = _this3.get('i18n').t('dialog.saved.text.operator', {
                    success: success.join(', ') || '-',
                    totalSuccess: success.length,
                    failed: failed.join(', ') || '-'
                });
                _this3.get('modalMessage').showDialog(title, text);
                _this3.refresh();
            });
        },
        getNextNewId: function getNextNewId() {
            // find highest new number
            var allOperators = this.get('store').peekAll('operator');

            var highestId = allOperators.reduce(function (prev, v) {
                var currId = v.get('id');
                if (currId && currId.startsWith(NEW_PREFIX)) {
                    var components = currId.split('-');
                    var number = parseInt(components[1], 10);
                    prev = isNaN(number) ? prev : number;
                }
                return prev;
            }, 0);

            return NEW_PREFIX + '-' + ++highestId;
        },


        actions: {
            search: function search() {
                var filter = this.get('viewData.search').toLowerCase();
                this.get('viewData.operators').forEach(function (operator) {
                    operator.set('filtered', filter && operator.get('companyName').toLowerCase().indexOf(filter) < 0);
                });
            },
            selectOperator: function selectOperator(operator) {
                // search current route name to maintain selected tab with the new selected operator
                var route = Ember.getOwner(this).lookup('controller:application').currentRouteName;
                this.set('viewData.selected', operator);
                if (route === 'operator.index' || route === 'operator.import') {
                    route = 'operator.operator.index';
                }
                this.transitionTo(route, operator.id);
            },
            deleteOperator: function deleteOperator(operator) {
                var _this4 = this;

                var title = this.get('i18n').t('dialog.delete.title', { name: operator.get('companyName') });
                var text = this.get('i18n').t('dialog.delete.text');
                this.get('modalMessage').confirm(title, text, function () {
                    operator.deleteRecord();
                    operator.save().then(function () {
                        _this4.transitionTo('operator.index');
                        title = _this4.get('i18n').t('message.title.success');
                        text = _this4.get('i18n').t('message.text.operator.delete.success');
                        _this4.get('modalMessage').showDialog(title, text);
                        _this4.refresh();
                    }, function () {
                        title = _this4.get('i18n').t('message.title.failure');
                        text = _this4.get('i18n').t('message.text.operator.delete.failure');
                        _this4.get('modalMessage').showDialog(title, text);
                    });
                });
            },
            copyOperator: function copyOperator(operator) {
                var copy = operator.copy();
                copy.set('id', this.getNextNewId());
                this.transitionTo('operator.operator.index', copy.get('id'));
            },
            newOperator: function newOperator() {
                var operator = this.draftOperator();
                this.transitionTo('operator.operator.index', operator.get('id'));
            },
            addOperator: function addOperator(json) {
                var store = this.get('store');
                // TODO `store.hasRecordForId()` always returns false, `id` property is never set.
                var operator = store.hasRecordForId('operator', json.id) ? store.peekRecord('operator', json.id) : this.getOperatorByBusiness(json.businessId) || this.draftOperator();
                operator.setProperties(json);
                this.loadOperatorsToViewData();
            },
            saveAll: function saveAll() {
                this.saveAll();
            },
            clearUnsaved: function clearUnsaved() {
                // En jumalauta kemsinyt miten emberissä saa hienosti rollbäckattyä dirty recordit
                // unloadAll toimi jossain tilanteissa mutta joskus tuli poikkeuksia mitä ei tietenkään
                // mitenkään pysty helposti debuggaan. Ember data on susi tuote.
                window.location.reload(true);
            },


            // receives id as parameter
            operatorSaved: function operatorSaved() {
                this.refresh();
            }
        }
    });
});