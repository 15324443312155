define('garage/routes/application', ['exports', 'ember-simple-auth-auth0/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
        // authentication

        session: Ember.inject.service('session'),
        i18n: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        // override the function implemented in `ember-simple-auth` to perform some custom actions
        sessionAuthenticated: function sessionAuthenticated() {
            // here we need to set an appropriate landing page based on permissions.
            this.set('routeAfterAuthentication', this.get('currentUser').getLandingPage(this.get('routeAfterAuthentication')));

            this._super.apply(this, arguments);
        },


        viewData: Ember.Object.create({
            session: null,
            locales: [{ lang: 'en', title: 'English' }, { lang: 'fi', title: 'Finnish' }]
        }),

        model: function model() {
            this.set('viewData.session', this.get('session'));

            return this.get('viewData');
        },


        actions: {
            setLocale: function setLocale(locale) {
                localStorage.setItem('userLocale', locale);

                this.set('i18n.locale', locale);
            }
        }
    });
});