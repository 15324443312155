define('garage/initializers/user-locale', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        var userLocale = localStorage.getItem('userLocale');
        var i18n = application.__container__.lookup('service:i18n');

        if (userLocale) {
            i18n.set('locale', userLocale);
        } else {
            i18n.set('locale', _environment.default.i18n.defaultLocale);
        }
    }

    exports.default = {
        name: 'user-locale',
        after: 'ember-i18n',
        initialize: initialize
    };
});