define('garage/user/model', ['exports', 'ember-data', 'garage/helpers/permission-utils'], function (exports, _emberData, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        verified: _emberData.default.attr('boolean'),
        password: _emberData.default.attr('string'),
        permissions: _emberData.default.attr(),
        comment: _emberData.default.attr('string'),

        role: Ember.computed('permissions.@each.id', function () {
            var p = this.get('permissions').filter(function (i) {
                return i.id.startsWith('role:');
            }).reduce(function (a, i) {
                return Object.assign(a, _defineProperty({}, i.id, 1));
            }, {});

            if (p.hasOwnProperty('role:super')) {
                return 'super';
            }

            if (p.hasOwnProperty(_permissionUtils.roleNames.admin)) {
                return 'admin';
            }

            if (p.hasOwnProperty(_permissionUtils.roleNames.operator)) {
                return 'operator';
            }

            return 'unknown';
        })
    });
});