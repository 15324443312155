define('garage/vehicle/vehicle/garage/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        viewData: Ember.Object.create({
            vehicle: null,
            markerLocation: null,
            text: null,
            map: {},

            viewLat: null,
            viewLng: null,
            viewAlias: null
        }),

        mapRef: null,

        model: function model() {
            var vehicle = this.modelFor('vehicle.vehicle').vehicle;

            this.set('viewData.vehicle', vehicle);

            // Start with full view of Finland, if no location.
            this.set('viewData.map', Ember.Object.create({
                lat: vehicle.get('garage.lat') || 64.38537023263848,
                lng: vehicle.get('garage.lng') || 26.01357060618094,
                zoom: vehicle.get('garage') ? 15 : 5
            }));

            this.setMarker(vehicle.get('garage'));

            return this.get('viewData');
        },


        /**
         * Move map to the given location.
         */
        setMarker: function setMarker(location) {
            var _this = this;

            this.set('viewData.markerLocation', location);

            if (location) {
                this.set('viewData.viewLat', location.lat);
                this.set('viewData.viewLng', location.lng);
                this.set('viewData.viewAlias', location.alias);
            } else {
                this.set('viewData.viewLat', null);
                this.set('viewData.viewLng', null);
                this.set('viewData.viewAlias', null);
            }

            if (!this.get('mapRef') || !location) {
                return;
            }

            // delay the operation to prevent the internal leaflet error
            setTimeout(function () {
                return _this.get('mapRef').setView(location, Math.max(15, _this.get('viewData.map.zoom')));
            });
        },
        clearGarageLocation: function clearGarageLocation() {
            this.set('viewData.vehicle.garage', null);

            this.setMarker(this.get('viewData.vehicle.garage'));
        },
        setGarageLocation: function setGarageLocation(location) {
            // make sure to trigger attribute change!
            this.set('viewData.vehicle.garage', Ember.Object.create(Object.assign(this.get('viewData.vehicle.garage') || {}, location)));

            this.setMarker(this.get('viewData.vehicle.garage'));
        },
        setGarageAlias: function setGarageAlias(alias) {
            this.set('viewData.vehicle.garage', Ember.Object.create(Object.assign(this.get('viewData.vehicle.garage') || {}, { alias: alias })));
        },


        actions: {
            locationSelected: function locationSelected(location) {
                var _ref = [location.position.lat, location.position.lon, location.type + ': ' + location.address.freeformAddress],
                    lat = _ref[0],
                    lng = _ref[1],
                    alias = _ref[2];


                if (lat && lng) {
                    this.setGarageLocation({ lat: lat, lng: lng, alias: alias });
                }
            },
            mapZoomed: function mapZoomed(e) {
                this.set('viewData.map.zoom', e.target.getZoom());
            },
            mapClicked: function mapClicked(e) {
                this.setGarageLocation(e.latlng);
            },
            mapLoaded: function mapLoaded(e) {
                this.set('mapRef', e.target);

                this.setMarker(this.get('viewData.vehicle.garage'));
            },
            updateAlias: function updateAlias(alias) {
                this.setGarageAlias(alias);
            },
            clearLocation: function clearLocation() {
                this.clearGarageLocation();
            }
        }
    });
});