define('garage/components/vinka-dropdown/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var count = 0;

    /**
     * A dropdown selector that can handle both promise based value lists or direct arrays.
     * For promise lists, we assume that `id` is used as the current value and `name` as
     * display text in the option list.
     *
     * Optional argument `style` can be used to give additional CSS-classes to the top level
     * component.
     *
     * Example:
     *
     *    {{vinka-dropdown options="[1,2,3,4]" current="model.number" onSelect="changeNumber"}}
     *
     */
    exports.default = Ember.Component.extend({

        name: null,
        id: null,
        hasPromise: null,

        i18n: Ember.inject.service(),

        init: function init() {
            this._super.apply(this, arguments);
            count++;
            this.set('id', 'vinka-dropdown-' + count);
        },
        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            var opts = this.get('options');
            var current = this.get('current');

            if (opts) {
                // Handle promise version.
                if (opts.then) {
                    this.set('hasPromise', true);
                    opts.then(function (data) {
                        _this.set('name', null);
                        data.forEach(function (v) {
                            if (current && parseInt(v.get('id')) === parseInt(current)) {
                                _this.set('name', v.get('name'));
                                return;
                            }
                        });
                        return data;
                    });
                } else {
                    // Handle straight data array.
                    this.set('hasPromise', false);
                    this.set('name', null);
                    opts.forEach(function (option, index) {
                        if (_typeof(opts[index]) !== 'object') {
                            opts[index] = { name: option, id: option };
                            if (option === current) {
                                _this.set('name', option);
                            }
                        } else {
                            // Note, ID here is just a storage for any value to make template handling easier.
                            if (option.id === current) {
                                _this.set('name', option.id);
                                return;
                            }
                        }
                    });
                }
            }
        },


        actions: {
            select: function select(val) {
                if (val === '') {
                    val = null;
                } else if (this.get('hasPromise')) {
                    val = Ember.Object.create({ id: val });
                }

                // This is done so the view using this component can revert the selection
                // Something like this can't be easily done within the onSelect function,
                // because the dropdown doesn't seem to re-render and revert back to the old value.
                var oldVal = this.get('current');
                this.set('current', val);
                this.get('onSelect')(val, oldVal);

                this.set('name', this.get('current'));
            }
        }
    });
});