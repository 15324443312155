define('garage/helpers/permission-utils', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.roleNames = undefined;
    exports.getSiteName = getSiteName;
    exports.convertToSiteRoles = convertToSiteRoles;
    exports.operatorRoleName = operatorRoleName;
    exports.adminRoleName = adminRoleName;
    exports.superRoleName = superRoleName;


    function getDomainName() {
        return window.location.hostname;
    }

    /**
     * Return site name based on application URL
     * (garage.site-name.vinka.fi or site-name.vinka.fi/garage).
     * If site name is not in domain format it is assumed to be run at localhost.
     * Then either default env name ENV.environment (started from command prompt) or null is returned.
     *
     * Note: this also affects required role-name. Auth0 should have role corresponding the environment.
     */
    //  -*- coding: utf-8 -*-
    //  app/helpers/permission-utils.js
    //  created: 2020-08-19T22:02:30
    //

    function getSiteName() {
        var domain = getDomainName();
        var parts = domain.split('.');
        var envSite = _environment.default.environment;

        if (parts.length === 3) {
            return [parts[0], '/garage/'];
        } else if (parts.length === 4) {
            return [parts[1], '/'];
        } else if (window.location.pathname.split('/').filter(function (p) {
            return !!p;
        })[0] === 'garage') {
            // localhost/garage/
            return [envSite || null, '/garage/'];
        } else {
            // localhost/
            return [envSite || null, '/'];
        }
    }

    function getSiteNameOrLocalhost() {
        var siteParams = getSiteName();
        return siteParams[0] ? siteParams[0] : 'localhost';
    }

    /**
     * Converts role:admin and role:operator permissions to site specific permissions.
     * Other permissions are returned as is.
     * @param {*} permissions Array of permission names.
     */
    function convertToSiteRoles(permissions) {
        return permissions.map(function (p) {
            if (p === 'role:admin' || p === 'role:operator') {
                return p.split('role:').join('role:' + getSiteNameOrLocalhost() + '-');
            }
            return p;
        });
    }

    function operatorRoleName() {
        return 'role:' + getSiteNameOrLocalhost() + '-operator';
    }

    function adminRoleName() {
        return 'role:' + getSiteNameOrLocalhost() + '-admin';
    }

    function superRoleName() {
        return 'role:super';
    }

    var roleNames = exports.roleNames = {
        get operator() {
            return 'role:' + getSiteNameOrLocalhost() + '-operator';
        },
        get admin() {
            return 'role:' + getSiteNameOrLocalhost() + '-admin';
        },
        get super() {
            return 'role:super';
        }
    };
});