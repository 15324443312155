define('garage/components/quill-wrapper/component', ['exports', 'quill'], function (exports, _quill) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var defaultSettings = {
        modules: {
            toolbar: [[{ header: [1, 2, 3, 4, false] }], ['bold', 'italic', 'underline', 'link']]
        },
        placeholder: '...',
        theme: 'snow'
    };

    /**
     * A WYSIWYG text editor component, in essence a wrapper for the quill library.
     * 
     * The editor is customizable with the same options available for a Quill instance,
     * with the prop "settings". See the available settings at https://quilljs.com/docs/configuration/.
     *  A default configuration is used if one is not provided.
     * 
     * It calls the onTextChange callback given as a prop, every time the contents within the editor
     * change. The contents are the actuar inner HTML produced within the editor. HTML is used because 
     * Quill only supports a non-standard markup.
     * 
     * The initial HTML content for the editor can be provided with prop intialContent.
     */
    exports.default = Ember.Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            if (this.get('editor')) {
                this.get('editor').root.innerHTML = this.get('initialContent') || '';
            }
        },
        didInsertElement: function didInsertElement() {
            var _this = this;

            this._super.apply(this, arguments);

            this.set('editor', new _quill.default('#editor', this.get('settings') || defaultSettings));
            this.get('editor').root.setAttribute('spellcheck', false);

            if (this.get('initialContent')) {
                this.get('editor').root.innerHTML = this.get('initialContent');
            }

            this.get('editor').on('text-change', function () {
                return _this.send('sendTextChange');
            });
        },
        getInnerHTML: function getInnerHTML() {
            return this.get('editor').root.innerHTML;
        },
        setInnerHTML: function setInnerHTML(html) {
            this.get('editor').root.innerHTML = html;
        },


        actions: {
            sendTextChange: function sendTextChange() {
                this.get('onTextChange')(this.getInnerHTML());
            }
        }
    });
});