define('garage/components/validatable-form', ['exports', 'ember-cli-html5-validation/components/validatable-form'], function (exports, _validatableForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _validatableForm.default;
    }
  });
});