define('garage/mixins/restricted-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
        currentUser: Ember.inject.service(),

        restrictions: [],

        beforeModel: function beforeModel() /*transition*/{
            var _this = this;

            //console.log(`page ristrictions: ${JSON.stringify(this.get('restrictions'))} user permissions: ${JSON.stringify(Object.keys(this.get('currentUser').get('permissions')))}`);
            if (this.get('currentUser.session.isAuthenticated') && !this.get('restrictions').some(function (i) {
                return _this.get('currentUser').hasPermission(i);
            })) {
                console.error('User does not have permission required for route');
                this.transitionTo(this.get('currentUser').getLandingPage('logout'));
            }

            return this._super.apply(this, arguments);
        }

    });
});