define('garage/operator/operator/reports/route', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),
        exporter: Ember.inject.service(),

        viewData: Ember.Object.create({
            startTime: null,
            endTime: null,
            report: null
        }),

        exportFileName: '',

        resetUi: function resetUi() {
            this.set('viewData.report', null);
        },
        model: function model() {
            this.resetUi();

            return this.get('viewData');
        },


        actions: {
            didTransition: function didTransition() {
                // send event to hide save buttons as those are not needed in this view
                this.send('hideSaveButtons');
            },
            generateReport: function generateReport() {
                var _this = this;

                this.set('viewData.report', null);

                if (!this.get('viewData.startTime') || !this.get('viewData.endTime')) {
                    return;
                }

                var locale = this.get('i18n.locale');
                var startTime = (0, _moment.default)(this.get('viewData.startTime')).startOf('day');
                var endTime = (0, _moment.default)(this.get('viewData.endTime')).endOf('day');

                this.exportFileName = 'operator-sums-' + startTime.format('YYYY_MM_DD') + '-' + endTime.format('YYYY_MM_DD');

                this.modelFor('operator.operator').operator.genReport({
                    type: 'sums',
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString()
                }).then(function (result) {
                    var formatCurrency = function formatCurrency(value) {
                        var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';

                        return Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
                    };

                    _this.set('viewData.report', result.data.map(function (r) {
                        r.startTime = r.startTime ? (0, _moment.default)(r.startTime).locale(locale).format('L') : '';
                        r.endTime = r.endTime ? (0, _moment.default)(r.endTime).locale(locale).format('L') : '';

                        // format all currency values
                        r.total = r.total ? formatCurrency(r.total) : '';
                        r.excess = r.excess ? formatCurrency(r.excess) : '';
                        r.vat = r.vat ? formatCurrency(r.vat) : '';

                        return r;
                    }));
                });
            },
            toExcel: function toExcel() {
                //this.get('exporter').toExcel(this.exportFileName, 'worksheet', 'route-history');
                this.get('exporter').toCSV(this.exportFileName, 'operator-sums');
            }
        }
    });
});