define('garage/operator/model', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(Ember.Copyable, {
        userId: _emberData.default.attr('string'),
        userEmail: _emberData.default.attr('string'),
        companyName: _emberData.default.attr('string'),
        companyAddress: _emberData.default.attr('string'),
        contactName: _emberData.default.attr('string'),
        contactPhone: _emberData.default.attr('string'),
        contactEmail: _emberData.default.attr('string'),
        businessId: _emberData.default.attr('string'),
        userIdEmail: null, // email fetched from user DB in runtime
        info: _emberData.default.attr('string'),
        externalId: _emberData.default.attr('string'),
        iban: _emberData.default.attr('string'),
        bic: _emberData.default.attr('string'),

        isUnsaved: Ember.computed('id', function () {
            return this.get('id') && this.get('id').startsWith('new');
        }),

        copy: function copy() {
            return this.get('store').createRecord('operator', {
                userId: this.get('userId'),
                userEmail: this.get('userEmail'),
                companyName: 'Copy of ' + this.get('companyName'),
                companyAddress: this.get('companyAddress'),
                contactName: this.get('contactName'),
                contactPhone: this.get('contactPhone'),
                contactEmail: this.get('contactEmail'),
                businessId: this.get('businessId'),
                info: this.get('info'),
                externalId: this.get('externalId'),
                iban: this.get('iban'),
                bic: this.get('bic')
            });
        },


        // ember-api-actions start here

        genReport: (0, _emberApiActions.memberAction)({ path: 'report', type: 'get' })
    });
});