define('garage/components/import-panel/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['import-area'],
        classNameBindings: ['dragClass'],

        dragActive: false,

        dragClass: Ember.computed('dragActive', function () {
            if (this.get('dragActive')) {
                return 'drag-active';
            } else {
                return '';
            }
        }),

        dragEnter: function dragEnter(e) {
            e.preventDefault();
            this.set('dragActive', true);
        },
        dragLeave: function dragLeave() /*e*/{
            // e.preventDefault();
            this.set('dragActive', false);
        },
        dragEnd: function dragEnd(e) {
            e.preventDefault();
            this.set('dragActive', false);
        },
        dragOver: function dragOver(e) {
            e.preventDefault();
        },
        drop: function drop(e /*, a*/) {
            e.preventDefault();
            this.set('dragActive', false);
            this.dropped(e);
        }
    });
});