define('garage/mirage/config', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function () {
        this.passthrough(_environment.default.APP.api.host + ':' + _environment.default.APP.api.port + '/**');
        this.passthrough(_environment.default.APP.auth.host + ':' + _environment.default.APP.auth.port + '/**');

        this.namespace = '/api';
        this.logging = false;

        function camelize(str) {
            return str.replace(/-([a-z])/g, function (g) {
                return g[1].toUpperCase();
            });
        }

        /**
         * Create API services for the given model.
         *
         * @param model Name of the model in plural.
         * @param patch Add also PATCH service, if set.
         * @param del Add also DELETE service, if set.
         * @param post Add also POST service, if set.
         * @param put Add also PUT service, if set.
         */
        this.serve = function (model) {
            var patch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
            var del = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

            var _this = this;

            var post = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
            var put = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;


            this.get('/' + model, function (schema) {
                return { data: schema.db[model.camelize()].where({}) };
            });

            this.get('/' + model + '/:id', function (schema, request) {
                return { data: schema.db[camelize(model)].find(request.params.id) };
            });

            if (patch) {
                var url = '/' + model + '/:id';
                this.patch(url, function (schema, request) {
                    var data = JSON.parse(request.requestBody).data;
                    console.log("PATCH " + _this.namespace + '/' + model + '/' + request.params.id, data);
                    schema.db[camelize(model)].update(request.params.id, data);
                    return { data: null };
                });
            }

            if (del) {
                var _url = '/' + model + '/:id';
                this.delete(_url, function (schema, request) {
                    console.log("DELETE " + _this.namespace + '/' + model + '/' + request.params.id);
                    schema.db[camelize(model)].remove(request.params.id);
                    return { data: null };
                });
                _url = '/' + model + '/:id/:resource/:rid';
                this.delete(_url, function (schema, request) {
                    console.log("DELETE " + _this.namespace + '/' + model + '/' + request.params.id + '/' + request.params.resource + '/' + request.params.rid);
                    return { data: null };
                });
            }

            if (post) {
                var _url2 = '/' + model;
                this.post(_url2, function (schema, request) {
                    var data = JSON.parse(request.requestBody).data;
                    console.log("POST " + _this.namespace + '/' + model, data);
                    var obj = schema.db[camelize(model)].insert(data);
                    console.log(" => New ID", obj.id);
                    return { data: obj };
                });

                _url2 = '/' + model + '/:id/:action';
                this.post(_url2, function (schema, request) {
                    var data = JSON.parse(request.requestBody);
                    console.log("POST " + _this.namespace + '/' + model + '/' + request.params.id + '/' + request.params.action, data);
                    return { data: null };
                });
            }

            if (put) {
                var _url3 = '/' + model + '/:id/:action';
                this.put(_url3, function (schema, request) {
                    var data = JSON.parse(request.requestBody);
                    console.log("PUT " + _this.namespace + '/' + model + '/' + request.params.id + '/' + request.params.action, data);
                    return { data: null };
                });
            }
        };

        this.serve('vehicles', true, true, true, true);
        this.serve('fleets');
        this.serve('operators');
        this.serve('vehicletypes');
        this.serve('shift-groups');
        this.serve('vehicle-attributes');
        this.serve('capacity-units');
    };
});