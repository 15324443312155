define('garage/helpers/field-access', ['exports', 'garage/helpers/permission-utils', 'garage/config/environment'], function (exports, _permissionUtils, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    // @todo: this implementation would allow for server returning set of fields names to be disabled
    var operatorDisabledFields = ['companyAddress', 'contactName', 'contactPhone', 'contactEmail', 'iban', 'bic', 'businessId', 'externalId', 'info'].map(function (i) {
        return 'operator.' + i;
    });

    exports.default = Ember.Helper.extend({
        currentUser: Ember.inject.service(),

        /**
         * This accepts list of accepted permission names and checks if user has some of those.
         * If not it is checked if field is part of disabled fieds (for e.g. operator).
         * For disabling, false is returned.
         * As a separation to has-permission helper, this may enable fields for operators.
         *
         * @param {*} params Must contain array where first item is the field name and rest items are admin permissions
         *  in which case field is enabled for user. E.g. ['fieldName', 'role:admin', 'role:super']
         */
        compute: function compute(params) {
            var _this = this;

            if (!_environment.default.APP.ui.disableOperatorInfos) {
                return true;
            }
            var fieldName = params[0];
            var adminPermissions = params.slice(1);
            var convertedPerms = (0, _permissionUtils.convertToSiteRoles)(adminPermissions);

            var hasAdminPerm = convertedPerms.some(function (i) {
                return _this.get('currentUser').hasPermission(i);
            });
            if (hasAdminPerm) {
                return true; // enabled for admins
            }

            // check if one of operator disabled fields
            return !operatorDisabledFields.includes(fieldName);
        }
    });
});