define('garage/operator/operator/driver/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        modalMessage: Ember.inject.service(),
        i18n: Ember.inject.service(),

        viewData: Ember.Object.create({
            filterText: '',
            allDrivers: Ember.A([]),
            filteredDrivers: [],
            operatorDrivers: Ember.A([]),
            operatorId: null
        }),

        model: function model() {
            var _this = this;

            var operatorId = +this.modelFor('operator.operator').operator.get('id');

            this.set('viewData.operatorId', operatorId);

            this.get('store').findAll('driver').then(function (result) {
                _this.set('viewData.allDrivers', result);
                _this.set('viewData.operatorDrivers', result.filter(function (i) {
                    return operatorId === i.get('operator');
                }));
            });

            return this.get('viewData');
        },


        actions: {
            didTransition: function didTransition() {
                // send event to hide save buttons as those are not needed in this view
                this.send('hideSaveButtons');
            },
            selectDriver: function selectDriver(dropdown, driver) {
                dropdown.actions.close();

                this.get('viewData.operatorDrivers').addObject(driver);

                this.set('viewData.filterText', '');
            },
            unselectDriver: function unselectDriver(driver) {
                this.get('viewData.operatorDrivers').removeObject(driver);
            },
            unlinkDriver: function unlinkDriver(driver) {
                driver.toggleProperty('isUnlinked');
            },
            saveDrivers: function saveDrivers() {
                var _this2 = this;

                var operatorId = this.get('viewData.operatorId');
                var chain = Promise.resolve();
                var errors = [];

                this.get('viewData.operatorDrivers').forEach(function (i) {
                    if (operatorId !== i.get('operator')) {
                        i.set('operator', operatorId);

                        chain = chain.then(function () {
                            return i.save().catch(function (error) {
                                errors.push({ driver: i, error: error });
                                i.rollbackAttributes();
                            });
                        });
                    } else if (i.get('isUnlinked')) {
                        i.set('operator', null);

                        chain = chain.then(function () {
                            return i.save().then(function () {
                                return i.set('isUnlinked', false);
                            }).catch(function (error) {
                                errors.push({ driver: i, error: error });
                                i.rollbackAttributes();
                            });
                        });
                    }
                });

                chain.then(function () {
                    errors = errors.map(function (i) {
                        return _this2.get('i18n').t('dialog.saved.text.driverToOperatorError', {
                            name: i.driver.get('name'),
                            error: i.error.errors ? i.error.errors.map(function (i) {
                                return i.message;
                            }).join('; ') : i.error.message ? i.error.message : 'unknown error'
                        });
                    }).join('');

                    var title = _this2.get('i18n').t('dialog.saved.title');
                    var text = errors.length === 0 ? _this2.get('i18n').t('dialog.saved.text.success') : _this2.get('i18n').t('dialog.saved.text.withErrors', { errors: errors });

                    _this2.get('modalMessage').showDialog(title, new DOMParser().parseFromString(text, 'text/html').documentElement.textContent);
                    _this2.refresh();
                });
            }
        },

        filterObserver: Ember.observer('viewData.filterText', function () {
            var _this3 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredDrivers', filter === '' ? [] : this.get('viewData.allDrivers').filter(function (i) {
                return _this3._filterDriver(i, filter);
            }));
        }),

        _filterDriver: function _filterDriver(driver, filter) {
            return ('' + driver.get('name')).toLowerCase().includes(filter) || ('' + driver.get('phone')).toLowerCase().includes(filter) || ('' + driver.get('email')).toLowerCase().includes(filter);
        }
    });
});