define('garage/index/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        beforeModel: function beforeModel() {
            this._super.apply(this, arguments);
            this.replaceWith('vehicle');
        }
    });
});