define('garage/initializers/html5-validation', ['exports', 'ember-cli-html5-validation/ext/checkbox', 'ember-cli-html5-validation/ext/text-area', 'ember-cli-html5-validation/ext/text-field'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'ember-cli-html5-validation',
    initialize: function initialize() {}
  };
});