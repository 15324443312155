define('garage/components/form-control', ['exports', 'ember-cli-html5-validation/components/form-control'], function (exports, _formControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _formControl.default;
    }
  });
});