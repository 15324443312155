define('garage/adapters/location', ['exports', 'ember-data', 'garage/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPIAdapter.extend({

        host: _environment.default.APP.geo.host + ':' + _environment.default.APP.geo.port,

        pathForType: function pathForType() /*type*/{
            return _environment.default.APP.geo.path;
        }
    });
});