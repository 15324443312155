define("garage/mirage/fixtures/vehicles", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "vehicle",
    "attributes": {
      "operatorId": 2,
      "fleetId": 1,
      "typeId": 1,
      "number": 82959,
      "name": "Kenneth",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }, {
        "unitId": 2,
        "count": 2
      }, {
        "unitId": 3,
        "count": 3
      }]],
      "shiftTemplates": [{
        "id": 23362,
        "startDay": "Monday",
        "startTime": "07:00",
        "endDay": "Monday",
        "endTime": "14:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 95605,
        "startDay": "Tuesday",
        "startTime": "11:00",
        "endDay": "Tuesday",
        "endTime": "19:00",
        "from": "2015-01-01",
        "to": "2015-12-31"
      }, {
        "id": 63602,
        "startDay": "Sunday",
        "startTime": "11:00",
        "endDay": "Sunday",
        "endTime": "19:00",
        "from": "2016-12-10",
        "to": "2016-12-31"
      }],
      "shiftGroups": [{
        "id": 76395,
        "name": "weekend",
        "templates": [{
          "id": 73928,
          "startDay": "Friday",
          "startTime": "08:00",
          "endDay": "Friday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 57434,
          "startDay": "Thursday",
          "startTime": "08:00",
          "endDay": "Thursday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Helsinki",
        lat: "60.161535",
        lng: "24.860269",
        street: "Puistokaari",
        streetNumber: 21
      }
    }
  }, {
    "id": 2, type: "vehicle",
    "attributes": {
      "operatorId": 2,
      "fleetId": 1,
      "typeId": 1,
      "number": 24211,
      "name": "Lillian",
      "capacityConfigs": [],
      "attributes": [],
      "shiftTemplates": [{
        "id": 78445,
        "startDay": "Friday",
        "startTime": "08:00",
        "endDay": "Friday",
        "endTime": "21:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 41064,
        "startDay": "Wednesday",
        "startTime": "06:00",
        "endDay": "Wednesday",
        "endTime": "19:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 1864,
        "startDay": "Wednesday",
        "startTime": "09:00",
        "endDay": "Wednesday",
        "endTime": "17:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 12547,
        "startDay": "Wednesday",
        "startTime": "08:00",
        "endDay": "Wednesday",
        "endTime": "14:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 19002,
        "startDay": "Friday",
        "startTime": "07:00",
        "endDay": "Friday",
        "endTime": "15:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 3, type: "vehicle",
    "attributes": {
      "operatorId": 2,
      "fleetId": 1,
      "typeId": 1,
      "number": 46292,
      "name": "Belle",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "attributes": [{ "id": 2 }],
      "shiftTemplates": [{
        "id": 46547,
        "startDay": "Thursday",
        "startTime": "11:00",
        "endDay": "Thursday",
        "endTime": "17:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 96675,
        "startDay": "Saturday",
        "startTime": "10:00",
        "endDay": "Saturday",
        "endTime": "20:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 79499,
        "startDay": "Friday",
        "startTime": "11:00",
        "endDay": "Friday",
        "endTime": "21:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 27516,
        "startDay": "Wednesday",
        "startTime": "12:00",
        "endDay": "Wednesday",
        "endTime": "16:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 81280,
        "startDay": "Saturday",
        "startTime": "10:00",
        "endDay": "Saturday",
        "endTime": "19:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [{
        "id": 62978,
        "name": "weekend",
        "templates": [{
          "id": 60837,
          "startDay": "Friday",
          "startTime": "12:00",
          "endDay": "Friday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 65716,
          "startDay": "Friday",
          "startTime": "08:00",
          "endDay": "Friday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 6523,
        "name": "evening",
        "templates": [{
          "id": 1274,
          "startDay": "Sunday",
          "startTime": "07:00",
          "endDay": "Sunday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 83259,
          "startDay": "Monday",
          "startTime": "06:00",
          "endDay": "Monday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 92868,
          "startDay": "Friday",
          "startTime": "11:00",
          "endDay": "Friday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 2539,
          "startDay": "Saturday",
          "startTime": "07:00",
          "endDay": "Saturday",
          "endTime": "20:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Järvenpää",
        lat: "60.509755",
        lng: "25.080914",
        street: "Asserinkuja",
        streetNumber: 1
      }
    }
  }, {
    "id": 4, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 1,
      "number": 26762,
      "name": "Corey",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [],
      "shiftGroups": [],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 5, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 1,
      "number": 61031,
      "name": "Jeffrey",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [{
        "id": 42652,
        "startDay": "Sunday",
        "startTime": "06:00",
        "endDay": "Sunday",
        "endTime": "14:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [{
        "id": 35430,
        "name": "morning",
        "templates": [{
          "id": 27317,
          "startDay": "Monday",
          "startTime": "07:00",
          "endDay": "Monday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 57435,
          "startDay": "Sunday",
          "startTime": "07:00",
          "endDay": "Sunday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 592,
          "startDay": "Saturday",
          "startTime": "09:00",
          "endDay": "Saturday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 72130,
          "startDay": "Friday",
          "startTime": "07:00",
          "endDay": "Friday",
          "endTime": "18:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 80154,
        "name": "evening",
        "templates": [{
          "id": 64283,
          "startDay": "Saturday",
          "startTime": "07:00",
          "endDay": "Saturday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 22798,
          "startDay": "Tuesday",
          "startTime": "06:00",
          "endDay": "Tuesday",
          "endTime": "15:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 6, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 1,
      "number": 34330,
      "name": "Sadie",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [],
      "shiftGroups": [{
        "id": 52522,
        "name": "morning",
        "templates": []
      }, {
        "id": 53770,
        "name": "evening",
        "templates": [{
          "id": 16420,
          "startDay": "Thursday",
          "startTime": "07:00",
          "endDay": "Thursday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 44311,
          "startDay": "Thursday",
          "startTime": "11:00",
          "endDay": "Thursday",
          "endTime": "19:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 77539,
          "startDay": "Tuesday",
          "startTime": "12:00",
          "endDay": "Tuesday",
          "endTime": "15:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Ikaalinen",
        lat: "61.906795",
        lng: "22.839255",
        street: "Välikyläntie",
        streetNumber: 1
      }
    }
  }, {
    "id": 7, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 1,
      "number": 22929,
      "name": "Josie",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [{
        "id": 91114,
        "startDay": "Friday",
        "startTime": "11:00",
        "endDay": "Friday",
        "endTime": "22:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 64112,
        "startDay": "Tuesday",
        "startTime": "08:00",
        "endDay": "Tuesday",
        "endTime": "13:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 77136,
        "startDay": "Friday",
        "startTime": "07:00",
        "endDay": "Friday",
        "endTime": "19:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [{
        "id": 27084,
        "name": "day",
        "templates": [{
          "id": 58052,
          "startDay": "Friday",
          "startTime": "07:00",
          "endDay": "Friday",
          "endTime": "20:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 97827,
        "name": "weekend",
        "templates": [{
          "id": 82206,
          "startDay": "Wednesday",
          "startTime": "07:00",
          "endDay": "Wednesday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 6534,
          "startDay": "Saturday",
          "startTime": "10:00",
          "endDay": "Saturday",
          "endTime": "19:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 38292,
          "startDay": "Tuesday",
          "startTime": "10:00",
          "endDay": "Tuesday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 74957,
        "name": "day",
        "templates": [{
          "id": 98561,
          "startDay": "Sunday",
          "startTime": "10:00",
          "endDay": "Sunday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 43674,
        "name": "morning",
        "templates": [{
          "id": 61026,
          "startDay": "Monday",
          "startTime": "10:00",
          "endDay": "Monday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 75121,
          "startDay": "Friday",
          "startTime": "06:00",
          "endDay": "Friday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 93034,
          "startDay": "Friday",
          "startTime": "11:00",
          "endDay": "Friday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 66988,
        "name": "weekend",
        "templates": [{
          "id": 10415,
          "startDay": "Thursday",
          "startTime": "07:00",
          "endDay": "Thursday",
          "endTime": "16:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 43994,
          "startDay": "Sunday",
          "startTime": "09:00",
          "endDay": "Sunday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 8, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 1,
      "number": 47587,
      "name": "Hattie",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [{
        "id": 99715,
        "startDay": "Thursday",
        "startTime": "12:00",
        "endDay": "Thursday",
        "endTime": "18:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 9, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 2,
      "number": 37069,
      "name": "Lora",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [],
      "shiftGroups": [{
        "id": 9862,
        "name": "day",
        "templates": [{
          "id": 30874,
          "startDay": "Saturday",
          "startTime": "11:00",
          "endDay": "Saturday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 10929,
          "startDay": "Saturday",
          "startTime": "11:00",
          "endDay": "Saturday",
          "endTime": "19:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 19631,
          "startDay": "Monday",
          "startTime": "11:00",
          "endDay": "Monday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 72610,
          "startDay": "Wednesday",
          "startTime": "09:00",
          "endDay": "Wednesday",
          "endTime": "19:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 36770,
          "startDay": "Tuesday",
          "startTime": "11:00",
          "endDay": "Tuesday",
          "endTime": "15:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 59989,
        "name": "morning",
        "templates": [{
          "id": 83083,
          "startDay": "Thursday",
          "startTime": "07:00",
          "endDay": "Thursday",
          "endTime": "22:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 98116,
          "startDay": "Thursday",
          "startTime": "06:00",
          "endDay": "Thursday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 40733,
          "startDay": "Saturday",
          "startTime": "08:00",
          "endDay": "Saturday",
          "endTime": "19:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 56005,
          "startDay": "Tuesday",
          "startTime": "10:00",
          "endDay": "Tuesday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 58605,
        "name": "morning",
        "templates": [{
          "id": 51689,
          "startDay": "Wednesday",
          "startTime": "09:00",
          "endDay": "Wednesday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }, {
    "id": 10, type: "vehicle",
    "attributes": {
      "operatorId": 1,
      "fleetId": 1,
      "typeId": 2,
      "number": 10123,
      "name": "Rosetta",
      "capacityConfigs": [[{
        "unitId": 1,
        "count": 1
      }]],
      "shiftTemplates": [{
        "id": 15434,
        "startDay": "Sunday",
        "startTime": "07:00",
        "endDay": "Sunday",
        "endTime": "17:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }],
      "shiftGroups": [{
        "id": 94094,
        "name": "day",
        "templates": [{
          "id": 20174,
          "startDay": "Sunday",
          "startTime": "09:00",
          "endDay": "Sunday",
          "endTime": "20:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 71638,
          "startDay": "Tuesday",
          "startTime": "12:00",
          "endDay": "Tuesday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 49302,
          "startDay": "Tuesday",
          "startTime": "11:00",
          "endDay": "Tuesday",
          "endTime": "20:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 46556,
          "startDay": "Tuesday",
          "startTime": "07:00",
          "endDay": "Tuesday",
          "endTime": "18:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 27992,
        "name": "day",
        "templates": [{
          "id": 87850,
          "startDay": "Monday",
          "startTime": "06:00",
          "endDay": "Monday",
          "endTime": "22:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 55288,
          "startDay": "Sunday",
          "startTime": "12:00",
          "endDay": "Sunday",
          "endTime": "20:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 80519,
          "startDay": "Tuesday",
          "startTime": "07:00",
          "endDay": "Tuesday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 34480,
        "name": "morning",
        "templates": []
      }, {
        "id": 90934,
        "name": "evening",
        "templates": [{
          "id": 29660,
          "startDay": "Tuesday",
          "startTime": "07:00",
          "endDay": "Tuesday",
          "endTime": "21:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 64381,
          "startDay": "Sunday",
          "startTime": "10:00",
          "endDay": "Sunday",
          "endTime": "14:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 28833,
          "startDay": "Wednesday",
          "startTime": "11:00",
          "endDay": "Wednesday",
          "endTime": "17:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 44830,
          "startDay": "Friday",
          "startTime": "11:00",
          "endDay": "Friday",
          "endTime": "13:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 3067,
          "startDay": "Saturday",
          "startTime": "09:00",
          "endDay": "Saturday",
          "endTime": "15:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }, {
        "id": 48108,
        "name": "weekend",
        "templates": [{
          "id": 67223,
          "startDay": "Wednesday",
          "startTime": "11:00",
          "endDay": "Wednesday",
          "endTime": "16:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 51850,
          "startDay": "Monday",
          "startTime": "09:00",
          "endDay": "Monday",
          "endTime": "18:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }, {
          "id": 51971,
          "startDay": "Monday",
          "startTime": "06:00",
          "endDay": "Monday",
          "endTime": "16:00",
          "from": "2016-01-01",
          "to": "2016-12-31"
        }]
      }],
      "description": "The Colonial Viper is a fictional spacecraft in the Battlestar Galactica science-fiction franchise.",
      "licenceNumber": "313",
      "phoneNumber": "0800-VIPER",
      "location": {
        city: "Tampere",
        lat: "61.497478",
        lng: "23.756274",
        street: "Hämeenkatu",
        streetNumber: 22
      }
    }
  }];
});