define("garage/mirage/fixtures/vehicle-attribute", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "vehicle-attribute",
    "attributes": {
      "name": "Alcolock"
    }
  }, {
    "id": 2, type: "vehicle-attribute",
    "attributes": {
      "name": "Easy access"
    }
  }];
});