define("garage/mirage/fixtures/capacity-units", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 1, type: "capacity-unit",
    "attributes": {
      "name": "Ambulatory",
      "loadTime": 170,
      "unloadTime": 75
    }
  }, {
    "id": 2, type: "capacity-unit",
    "attributes": {
      "name": "Wheelchair",
      "loadTime": 210,
      "unloadTime": 28
    }
  }, {
    "id": 3, type: "capacity-unit",
    "attributes": {
      "id": 3,
      "name": "Walker",
      "loadTime": 20,
      "unloadTime": 55
    }
  }, {
    "id": 4, type: "capacity-unit",
    "attributes": {
      "name": "Dog",
      "loadTime": 207,
      "unloadTime": 271
    }
  }];
});