define('garage/logout/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        session: Ember.inject.service('session'),

        actions: {
            didTransition: function didTransition() {
                this.get('session').invalidate();
                this.transitionTo('login');
            }
        }
    });
});