define('garage/vehicle/vehicle/shift/route', ['exports', 'garage/helpers/api-error-handler', 'moment'], function (exports, _apiErrorHandler, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),
        store: Ember.inject.service(),

        viewData: Ember.Object.create({
            vehicle: null,
            allvehicles: [],
            shiftTemplates: [],
            shiftColumns: Ember.A([{ name: 'startDay', localize: true, sortAs: 'day', defaultSort: 0 }, { name: 'startTimeClock', sortAs: 'time', defaultSort: 1 }, { name: 'endDay', localize: true, sortAs: 'day' }, { name: 'endTimeClock', sortAs: 'time' }, { name: 'startTimeDate', sortAs: 'date' }, { name: 'endTimeDate', sortAs: 'date' }]),
            days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
            selectedShift: null,
            editingShift: false,
            shiftToEdit: null,
            editingExisting: true,
            copyToVehicle: '',
            selectedCopyDay: null
        }),

        model: function model() {
            var _this = this;

            this.set('viewData.selectedShift', null);
            this.set('viewData.vehicle', this.modelFor('vehicle.vehicle').vehicle);
            this.set('viewData.shiftTemplates', []);

            return this._loadShiftTemplates(this.get('viewData.vehicle')).then(function () {
                _this.set('viewData.editingShift', false);
                // get vehicles and convert to vinka-dropdown form
                return _this.get('store').findAll('vehicle').then(function (result) {
                    var vehicles = result.sortBy('number').map(function (item) {
                        return '' + item.get('number');
                    });
                    _this.set('viewData.allVehicles', vehicles);
                    return _this.get('viewData');
                });
            });
        },
        _getTranslatedDays: function _getTranslatedDays() {
            return [{ id: 'Monday', name: this.get('i18n').t('weekday.Monday') }, { id: 'Tuesday', name: this.get('i18n').t('weekday.Tuesday') }, { id: 'Wednesday', name: this.get('i18n').t('weekday.Wednesday') }, { id: 'Thursday', name: this.get('i18n').t('weekday.Thursday') }, { id: 'Friday', name: this.get('i18n').t('weekday.Friday') }, { id: 'Saturday', name: this.get('i18n').t('weekday.Saturday') }, { id: 'Sunday', name: this.get('i18n').t('weekday.Sunday') }];
        },
        _loadShiftTemplates: function _loadShiftTemplates(vehicle) {
            var _this2 = this;

            if (vehicle) {
                return this.get('store').query('shift-template', { adapterOptions: { vehicleId: vehicle.get('id') } }).then(function (ret) {
                    _this2.set('viewData.shiftTemplates', ret);
                });
            } else {
                return Promise.resolve();
            }
        },


        /**
         * Reload the vehicle data after API call has changed it.
         */
        refresh: function refresh() {
            // load shift templates again
            this._loadShiftTemplates(this.get('viewData.vehicle'));
        },


        /**
         * Return combined start/end date and time values
         * @returns object {startTime: null, endTime: null}
         */
        getEditStartEndTime: function getEditStartEndTime() {
            var shift = this.get('viewData.shiftToEdit');
            var from = shift.get('from');
            var to = shift.get('to');
            var ret = {
                startTime: null,
                endTime: null
            };

            if (from) {
                var time = shift.get('startTime');
                ret.startTime = this.mergeTime(from, time);
            }
            if (to) {
                var _time = shift.get('endTime');
                ret.endTime = this.mergeTime(to, _time);
            }
            return ret;
        },
        mergeTime: function mergeTime(date, time) {
            var newDate = new Date(date);
            var comp = time ? time.split(':') : [];
            if (comp.length !== 2) {
                return newDate;
            }
            newDate.setHours(comp[0]);
            newDate.setMinutes(comp[1]);
            return newDate;
        },
        copyShiftToVehicle: function copyShiftToVehicle(shift, vehicleId) {
            var _this3 = this;

            var data = this.copyShift(shift);
            data.vehicle = vehicleId;
            this.get('store').createRecord('shift-template', data).save({ adapterOptions: { vehicleId: vehicleId } }).then(function () {
                // saved
            }).catch(function (e) {
                var title = _this3.get('i18n').t('message.title.failure');
                var text = (0, _apiErrorHandler.formatApiError)(e, _this3.get('i18n'), 'shiftTpl');
                _this3.get('modalMessage').showDialog(title, text);
            });
        },


        /**
         * Copy shift to another day for the same vehicle. Copies only shift containing one day.
         * @param {*} shift
         * @param {*} day
         * @returns null if everything went fine. Failure text otherwise.
         */
        copyShiftForDay: function copyShiftForDay(shift, day) {
            var _this4 = this;

            var data = this.copyShift(shift);
            data.startDay = day;
            data.endDay = day;
            return this.get('store').createRecord('shift-template', data).save({ adapterOptions: { vehicleId: shift.get('vehicle') } }).then(function () {
                return null;
            }).catch(function (e) {
                var text = (0, _apiErrorHandler.formatApiError)(e, _this4.get('i18n'), 'shiftTpl');
                return text;
            });
        },
        copyShift: function copyShift(shift) {
            return {
                startDay: shift.get('startDay'),
                startTime: shift.get('startTime'),
                startClock: shift.get('startClock'),
                endDay: shift.get('endDay'),
                endTime: shift.get('endTime'),
                endClock: shift.get('endClock'),
                vehicle: shift.get('vehicle')
            };
        },


        actions: {
            didTransition: function didTransition() {
                // send event to hide save buttons as those are not needed in this view
                this.send('hideSaveButtons');
            },
            selectShift: function selectShift(shift) {
                this.set('viewData.selectedShift', shift);
            },
            removeSelectedShift: function removeSelectedShift() {
                var _this5 = this;

                var shift = this.get('viewData.selectedShift');
                shift.destroyRecord({ adapterOptions: { vehicleId: this.get('viewData.vehicle.id') } }).then(function () {
                    _this5.refresh();
                });
            },
            copyShift: function copyShift() {
                this.set('viewData.copyToVehicle', '');
                Ember.$('#copyShift').modal('show');
            },
            copyShiftForDay: function copyShiftForDay() {
                this.set('viewData.selectedCopyDay', null);
                Ember.$('#copyShiftForDay').modal('show');
            },
            copyDaySelected: function copyDaySelected(dayName) {
                this.set('viewData.selectedCopyDay', dayName);
            },
            copyVehicleSelected: function copyVehicleSelected(veh) {
                this.set('viewData.copyToVehicle', veh);
            },
            doCopyShiftForDay: function doCopyShiftForDay() {
                var _this6 = this;

                Ember.$('#copyShiftForDay').modal('hide');

                var shift = this.get('viewData.selectedShift');
                if (shift) {
                    if (shift.get('startDay') === shift.get('endDay')) {
                        this.copyShiftForDay(shift, this.get('viewData.selectedCopyDay')).then(function (error) {
                            if (error !== null) {
                                // error
                                var title = _this6.get('i18n').t('message.title.failure');
                                _this6.get('modalMessage').showDialog(title, error);
                            } else {
                                _this6.refresh();
                            }
                        });
                    } else {
                        // we do not accept over night shift copying (due to simplicity for now)
                        var title = this.get('i18n').t('message.title.failure');
                        var error = this.get('i18n').t('messages.shiftTpl.overNightShiftCopyError');
                        this.get('modalMessage').showDialog(title, error);
                    }
                }
            },
            doCopyShift: function doCopyShift() {
                Ember.$('#copyShift').modal('hide');
                var shift = this.get('viewData.selectedShift');
                var vehicleId = this.get('viewData.copyToVehicle');
                if (shift && vehicleId) {
                    this.copyShiftToVehicle(shift, vehicleId);
                }
            },
            editSelectedShift: function editSelectedShift() {
                var shift = this.get('viewData.selectedShift');
                this.set('viewData.shiftToEdit', Ember.Object.create({
                    startDay: shift.get('startDay'),
                    startTime: shift.get('startTimePicker'),
                    endDay: shift.get('endDay'),
                    endTime: shift.get('endTimePicker'),
                    from: shift.get('startTime'),
                    to: shift.get('endTime'),
                    vehicle: this.get('viewData.vehicle.id')
                }));

                this.set('viewData.editingShift', true);
                this.set('viewData.editingExisting', shift);
            },
            addShift: function addShift() {
                var _this7 = this;

                if (this.get('viewData.editingShift')) {
                    var times = this.getEditStartEndTime(); // get combined start time and date values
                    var shift = this.get('viewData.shiftToEdit');
                    var data = {
                        startDay: shift.get('startDay'),
                        startTime: times.startTime,
                        startClock: times.startTime ? (0, _moment.default)(times.startTime).format('HH:mm') : null,
                        endDay: shift.get('endDay'),
                        endTime: times.endTime,
                        endClock: times.endTime ? (0, _moment.default)(times.endTime).format('HH:mm') : null,
                        vehicle: this.get('viewData.vehicle.id')
                    };
                    this.get('store').createRecord('shift-template', data).save({ adapterOptions: { vehicleId: this.get('viewData.vehicle.id') } }).then(function () {
                        _this7.set('viewData.editingShift', false);
                        _this7.refresh();
                    }).catch(function (e) {
                        var title = _this7.get('i18n').t('message.title.failure');
                        var text = _this7.get('i18n').t('message.text.shift.new.failure');
                        text = (0, _apiErrorHandler.formatApiError)(e, _this7.get('i18n'), 'shiftTpl');
                        _this7.get('modalMessage').showDialog(title, text);
                    });
                } else {
                    this.set('viewData.shiftToEdit', Ember.Object.create({
                        startDay: null, startTime: null, endDay: null, endTime: null,
                        to: null, from: null, vehicle: this.get('viewData.vehicle.id') }));

                    this.set('viewData.editingShift', true);
                    this.set('viewData.editingExisting', null);
                }
            },
            cancelAdding: function cancelAdding() {
                if (this.get('viewData.selectedShift.hasDirtyAttributes')) {
                    this.get('viewData.selectedShift').rollbackAttributes();
                }
                this.set('viewData.editingShift', false);
                this.set('viewData.editingExisting', null);
            },
            changeStartDay: function changeStartDay(day) {
                this.set('viewData.shiftToEdit.startDay', day);
            },
            changeEndDay: function changeEndDay(day) {
                this.set('viewData.shiftToEdit.endDay', day);
            },
            saveShift: function saveShift() {
                var _this8 = this;

                var shift = this.get('viewData.editingExisting');
                shift.setProperties({
                    startDay: this.get('viewData.shiftToEdit.startDay'),
                    endDay: this.get('viewData.shiftToEdit.endDay')
                });
                try {
                    // Todo: should add time picker to get constant format
                    shift.setStartTime(this.get('viewData.shiftToEdit.from'), this.get('viewData.shiftToEdit.startTime'));
                    shift.setEndTime(this.get('viewData.shiftToEdit.to'), this.get('viewData.shiftToEdit.endTime'));
                    shift.save({ adapterOptions: { vehicleId: this.get('viewData.vehicle.id') } }).then(function () {
                        _this8.set('viewData.editingShift', false);
                        _this8.set('viewData.editingExisting', null);
                        _this8.refresh();
                    }).catch(function (err) {
                        var title = _this8.get('i18n').t('message.title.failure');
                        var text = _this8.get('i18n').t('message.text.shift.new.failure');
                        text = (0, _apiErrorHandler.formatApiError)(err, _this8.get('i18n'), 'shiftTpl');
                        _this8.get('modalMessage').showDialog(title, text);
                    });
                } catch (e) {
                    console.log('error', e);
                }
            }
        }
    });
});