define('garage/modal-message/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        i18n: Ember.inject.service(),

        /**
         * Create a message box that can be dismissied by calling `remove()` of this service.
         *
         * @param title The title text of the message box.
         * @param text Actual message content as a string.
         * @param spinner If set, show spinner image.
         */
        show: function show(title, text) {
            var spinner = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;


            var img = '';
            if (spinner) {
                img = '<img src="/img/ajax-loader.gif">';
            }

            var html = '\n            <div class="small-modal-overlay">\n                <div class="small-modal-message">\n                    ' + img + '\n                    <div class="title">' + title + '</div>\n                    <div class="text">' + text + '</div>\n                </div>\n            </div>\n            ';
            Ember.$('body').append(html);
        },


        /**.small-modal-overlay
         * Show bootstrap based closable dialog overlay.
         * @param {*} title
         * @param {*} text
         */
        showDialog: function showDialog(title, text) {
            var closeLabel = this.get('i18n').t('dialog.button.close');

            var html = '\n        <div class="modal fade" id="garageModal" tabindex="-1" role="dialog" aria-labelledby="garageModalLabel" aria-hidden="true">\n            <div class="modal-dialog" role="document">\n                <div class="modal-content">\n                    <div class="modal-header">\n                    <h5 class="modal-title" id="garageModalLabel">' + title + '</h5>\n                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n                        <span aria-hidden="true">&times;</span>\n                    </button>\n                    </div>\n                    <div class="modal-body">\n                        <div class="text">' + text + '</div>\n                    </div>\n                    <div class="modal-footer">\n                    <button type="button" class="btn btn-secondary" data-dismiss="modal">' + closeLabel + '</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n            ';
            Ember.$('body').append(html);
            Ember.$('#garageModal').modal();
            Ember.$('#garageModal').on('hidden.bs.modal', function () {
                Ember.run(function () {
                    Ember.$('#garageModal').remove();
                });
            });
        },


        /**
         * Remove message box from the screen.
         */
        remove: function remove() {
            Ember.$('.small-modal-overlay').remove();
        },


        /**
         * Show a message box and then automatically remove it after a delay.
         */
        message: function message(title, text) {
            var _this = this;

            this.get('show')(title, text, false);
            Ember.run.later(function () {
                return _this.get('remove')();
            }, 2000);
        },


        /**
         * Create confirmation dialog and run call back based on the choice.
         *
         * @param title The title text of the message box.
         * @param text Actual message content as a string.
         * @param yes A function to execute if the `yes` option was selected.
         * @param no A function to execute if the `no` option was selected or dialog was dismissed with `Esc`.
         */
        confirm: function confirm(title, text) {
            var _yes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {};

            var _no = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};

            var yesLabel = this.get('i18n').t('dialog.button.yes');
            var noLabel = this.get('i18n').t('dialog.button.no');

            var html = '\n  <div class="modal" id="small-modal-dialog" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">\n    <div class="small-modal-dialog">\n     <div class="modal-content">\n        <div class="modal-header">\n          <h4 class="modal-title">' + title + '</h4>\n        </div>\n        <div class="modal-body">\n            ' + text + '\n        </div>\n        <div class="modal-footer">\n          <button type="button" onClick="window.VinkaModal.yes()" class="btn btn-secondary" data-dismiss="modal">' + yesLabel + '</button>\n          <button type="button" onClick="window.VinkaModal.no()" class="btn btn-primary" data-dismiss="modal">' + noLabel + '</button>\n        </div>\n    </div>\n    </div>\n</div>\n';
            window.VinkaModal = {
                yes: function yes() {
                    return _yes();
                },
                no: function no() {
                    return _no();
                }
            };

            Ember.$('body').append(html);
            Ember.$('#small-modal-dialog').modal({ backdrop: 'static', keyboard: true });
            Ember.$('#small-modal-dialog').on('hidden.bs.modal', function () {
                return Ember.$('#small-modal-dialog').remove();
            });
        }
    });
});