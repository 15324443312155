define('garage/license-settings/route', ['exports', 'garage/mixins/restricted-route-mixin', 'garage/config/environment', 'garage/helpers/permission-utils'], function (exports, _restrictedRouteMixin, _environment, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {

        i18n: Ember.inject.service(),

        modalMessage: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            // License which is edited in the editor
            editingLicense: '',
            // The selected language
            selectedLanguage: null,
            // Licenses from the data store
            licenses: [],
            // Available languages to create licenses in
            languages: [],
            // For finding language code by language name
            languageCodes: {},
            // Summary of available languages
            languageSummary: '',
            // For quill editor initial contents after language select
            initialLicenseContent: ''
        }),

        model: function model() {
            var _this = this;

            this.get('store').findAll('license').then(function (result) {
                _this.set('viewData.licenses', result);
            }).then(function () {
                // Set a string summary of languages in which licenses have been created,
                // if there are any. Appends a translation to the beginning of the string.
                // Example: "You have created licenses in the following languages: Finnish, English."
                if (!Ember.isEmpty(_this.viewData.licenses)) {
                    _this.set('viewData.languageSummary', _this.get('i18n').t('etc.license.createdLicenses', {
                        languages: _this.get('viewData.licenses').map(function (license) {
                            return _this.get('i18n').t('languages.' + license.get('language')).string;
                        }).join(', ')
                    }));
                }
            });

            var languages = [];

            _environment.default.APP.ui.languages.forEach(function (language) {
                var translation = _this.get('i18n').t('languages.' + language).string;
                languages.push(translation);
                _this.viewData.languageCodes[translation] = language;
            });

            this.set('viewData.languages', languages);

            return this.get('viewData');
        },
        getLanguageCodeByName: function getLanguageCodeByName(languageName) {
            return this.viewData.languageCodes[languageName || this.viewData.selectedLanguage];
        },
        getLicenseByLanguage: function getLicenseByLanguage(languageName) {
            return this.viewData.licenses.findBy('language', this.getLanguageCodeByName(languageName));
        },
        getLicenseBySelectedLanguage: function getLicenseBySelectedLanguage() {
            return this.getLicenseByLanguage(this.viewData.selectedLanguage);
        },
        editingLicenseIsEmpty: function editingLicenseIsEmpty() {
            return this.viewData.editingLicense === '' || this.viewData.editingLicense === '<p><br></p>';
        },


        actions: {
            onTextChange: function onTextChange(newText) {
                this.set('viewData.editingLicense', newText);
            },
            setLanguage: function setLanguage(language, oldLang) {
                var _this2 = this;

                var data = this.viewData;
                var licenseBefore = this.getLicenseByLanguage(oldLang);

                var changeLanguage = function changeLanguage() {
                    _this2.set('viewData.selectedLanguage', language);
                    var license = _this2.getLicenseBySelectedLanguage();
                    _this2.set('viewData.editingLicense', license === undefined ? '' : license.get('license'));
                    _this2.set('viewData.initialLicenseContent', license === undefined ? '' : license.get('license'));
                };

                // If license has changed or is new
                if (!this.editingLicenseIsEmpty() && (licenseBefore === undefined || data.editingLicense !== licenseBefore.get('license'))) {
                    this.get('modalMessage').confirm(this.get('i18n').t('dialog.changeLangNoSave.title'), this.get('i18n').t('dialog.changeLangNoSave.text'), changeLanguage, function () {
                        return _this2.viewData.set('selectedLanguage', oldLang);
                    });
                } else {
                    changeLanguage();
                }
            },
            saveLicense: function saveLicense() {
                var _this3 = this;

                var onConfirm = function onConfirm() {
                    var license = _this3.getLicenseBySelectedLanguage();

                    try {
                        if (license === undefined) {
                            _this3.get('store').createRecord('license', {
                                license: _this3.viewData.editingLicense,
                                language: _this3.getLanguageCodeByName(_this3.viewData.selectedLanguage)
                            }).save();
                        } else {
                            _this3.get('store').findRecord('license', license.id).then(function (_license) {
                                _license.set('license', _this3.viewData.editingLicense);
                                _license.save();
                            });
                        }
                    } catch (err) {}
                };

                this.get('modalMessage').confirm(this.get('i18n').t('dialog.saveLicense.title', { language: this.viewData.selectedLanguage }), this.get('i18n').t('dialog.saveLicense.text'), onConfirm);
            }
        }
    });
});