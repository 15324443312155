define('garage/application/adapter', ['exports', 'ember-data', 'garage/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
        // namespace: 'api',
        session: Ember.inject.service('session'),
        host: '' + _environment.default.APP.api.host,

        authorize: function authorize(xhr) {
            var accessToken = this.get('session.data.authenticated.accessToken');
            xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
        },


        // response handler for converting api errors into form, that ember DS accepts
        // (otherwise error shows as string "[object object]")
        handleResponse: function handleResponse(status, headers, payload) {
            if (status >= 400 && status < 500) {
                var errors = payload.errors ? payload.errors : [payload];
                if (status === 401) {
                    console.log('should redirect to login route');
                    this.get('session').invalidate();
                }
                return new _emberData.default.InvalidError(errors);
            }

            return this._super.apply(this, arguments);
        }
    });
});