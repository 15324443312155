define('garage/mirage/scenarios/default', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    exports.default = function (server) {

        server.loadFixtures('vehicles');
        server.loadFixtures('operators');
        server.loadFixtures('fleets');
        server.loadFixtures('vehicletypes');
        server.loadFixtures('shift-groups');
        server.loadFixtures('capacity-units');
        server.loadFixtures('vehicle-attributes');
    };
});