define('garage/helpers/has-permission', ['exports', 'garage/helpers/permission-utils'], function (exports, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        currentUser: Ember.inject.service(),

        /**
         * this accepts list of permission names and checks if user has some of those.
         * if permission is given in old form like: role:admin or role:operator, we use convertToSiteRoles
         * function to convert them to site specific permissions, like role:demo-operator
         * @param {*} permissions
         */
        compute: function compute(permissions) {
            var _this = this;

            var convertedPerms = (0, _permissionUtils.convertToSiteRoles)(permissions);

            return convertedPerms.some(function (i) {
                return _this.get('currentUser').hasPermission(i);
            });
        }
    });
});