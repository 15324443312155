define('garage/router', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend({
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL
    });

    Router.map(function () {
        this.route('vehicle', function () {
            this.route('vehicle', { path: ':id' }, function () {
                this.route('capacity');
                this.route('attribute');
                this.route('shift');
                this.route('garage');
                this.route('terminal');
            });
            this.route('import');
        });
        this.route('operator', function () {
            this.route('operator', { path: ':id' }, function () {
                this.route('vehicle');
                this.route('driver');
                this.route('report');
                this.route('reports');
            });
            this.route('import');
        });
        this.route('driver', function () {
            this.route('driver', { path: ':id' }, function () {});
        });
        this.route('user');
        this.route('login');
        this.route('logout');
        this.route('license-settings');
    });

    exports.default = Router;
});