define('garage/exporter/service', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    var EXCEL_URI = 'data:application/vnd.ms-excel;base64,';
    var CSV_URI = 'data:text/csv;charset=utf-8,';
    var EXCEL_TEMPLATE = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>';

    exports.default = Ember.Service.extend({
        toCSV: function toCSV(fileName, tableId) {
            var separator = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ',';

            var prepareCell = function prepareCell(cell) {
                return cell.innerText
                // replace all possible whitespaces and linebreaks with a regular space
                .replace(/\s+/gm, ' ')
                // remove leading and trailing spaces
                .trim()
                // escape all double-quotes by replacing them with doubled double-quotes
                .replace(/"/g, '""')
                // wrap the string with double-quotes
                .replace(/^(.*)$/, '"$1"');
            };

            var prepareRow = function prepareRow(row) {
                return [].concat(_toConsumableArray(row.querySelectorAll('td, th')))
                // convert cell to a sanitized escaped string
                .map(prepareCell)
                // join these strings with a separator (comma by default)
                .join(separator);
            };

            var data = [].concat(_toConsumableArray(document.querySelectorAll('table#' + tableId + ' tr')))
            // convert row to a string of escaped comma separated values
            .map(prepareRow)
            // join these strings with a linebreak
            .join('\n');

            this.initFileDownload('' + CSV_URI + encodeURIComponent(data), fileName + '.csv');
        },
        toExcel: function toExcel(fileName, sheetName, tableId) {
            var clone = document.getElementById(tableId).cloneNode(true);

            // prevent Spreadsheet apps from auto-formatting the data by wrapping it into the `="${...}"`
            var cloneCells = clone.getElementsByTagName('td');
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = cloneCells[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var cloneCell = _step.value;

                    cloneCell.innerHTML = '="' + cloneCell.innerHTML + '"';
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }

            var xlsData = this.populateExcelTemplate({ worksheet: sheetName || 'Worksheet', table: clone.innerHTML });

            this.initFileDownload(EXCEL_URI + this.base64Encode(xlsData), fileName + '.xlsx');
        },
        populateExcelTemplate: function populateExcelTemplate(data) {
            return EXCEL_TEMPLATE.replace(/{(\w+)}/g, function (m, p) {
                return data[p];
            });
        },
        base64Encode: function base64Encode(string) {
            return window.btoa(unescape(encodeURIComponent(string)));
        },
        initFileDownload: function initFileDownload(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            link.click();
        }
    });
});