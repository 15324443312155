define('garage/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/capacity-units.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/capacity-units.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/fleets.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/fleets.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/operators.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/operators.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/shift-groups.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/shift-groups.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/vehicle-attribute.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/vehicle-attribute.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/vehicles.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/vehicles.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/fixtures/vehicletype.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/fixtures/vehicletype.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});