define('garage/operator/operator/vehicle/route', ['exports', 'file-saver'], function (exports, _fileSaver) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        modalMessage: Ember.inject.service(),
        i18n: Ember.inject.service(),

        viewData: Ember.Object.create({
            filterText: '',
            allVehicles: Ember.A([]),
            filteredVehicles: [],
            operatorVehicles: Ember.A([]),
            operatorId: null,
            qrCodes: {}
        }),

        model: function model() {
            var _this = this;

            var operatorId = +this.modelFor('operator.operator').operator.get('id');

            this.set('viewData.operatorId', operatorId);

            this.get('store').findAll('vehicle').then(function (result) {
                _this.set('viewData.allVehicles', result);

                _this.set('viewData.operatorVehicles', result.filter(function (i) {
                    return operatorId === i.get('operator');
                }));
            });

            return this.get('viewData');
        },


        actions: {
            didTransition: function didTransition() {
                // send event to hide save buttons as those are not needed in this view
                this.send('hideSaveButtons');
            },
            selectVehicle: function selectVehicle(dropdown, vehicle) {
                dropdown.actions.close();

                this.get('viewData.operatorVehicles').addObject(vehicle);

                this.set('viewData.filterText', '');
            },
            unselectVehicle: function unselectVehicle(vehicle) {
                this.get('viewData.operatorVehicles').removeObject(vehicle);
            },
            unlinkVehicle: function unlinkVehicle(vehicle) {
                vehicle.toggleProperty('isUnlinked');
            },
            saveVehicles: function saveVehicles() {
                var operatorId = this.get('viewData.operatorId');

                this.get('viewData.operatorVehicles').forEach(function (i) {
                    if (operatorId !== i.get('operator')) {
                        i.set('operator', operatorId);
                        i.save(); //console.log(`add new binding for ${i.get('id')}`);
                    } else if (i.get('isUnlinked')) {
                        i.set('operator', null);
                        i.set('isUnlinked', false);
                        i.save(); //console.log(`remove binding for ${i.get('id')}`);
                    }
                });

                this.refresh();
            },
            getQRCode: function getQRCode(vehicle) {
                var _this2 = this;

                var qrCode = this.get('viewData.qrCodes.' + vehicle.get('number'));

                if (qrCode) {
                    _fileSaver.saveAs(qrCode.data, qrCode.name);
                } else {
                    vehicle.genQRCode().then(function (response) {
                        _this2.set('viewData.qrCodes.' + vehicle.get('number'), response);
                        var qrCode = _this2.get('viewData.qrCodes.' + vehicle.get('number'));

                        _fileSaver.saveAs(qrCode.data, qrCode.name);
                    });
                }
            }
        },

        filterObserver: Ember.observer('viewData.filterText', function () {
            var _this3 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredVehicles', filter === '' ? [] : this.get('viewData.allVehicles').filter(function (i) {
                return _this3._filterVehicle(i, filter);
            }));
        }),

        _filterVehicle: function _filterVehicle(vehicle, filter) {
            return ('' + vehicle.get('name')).toLowerCase().includes(filter) || ('' + vehicle.get('phone_number')).toLowerCase().includes(filter) || ('' + vehicle.get('number')).toLowerCase().includes(filter);
        }
    });
});