define('garage/operator/operator/index/route', ['exports', 'garage/helpers/permission-utils'], function (exports, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentUser: Ember.inject.service(),

        viewData: Ember.Object.create({
            filterText: '',
            users: [],
            filteredUsers: [],
            operator: null
        }),

        model: function model() {
            var _this = this;

            var operator = this.modelFor('operator.operator').operator;
            this.set('viewData.operator', operator);

            if (this.get('currentUser').hasPermission(_permissionUtils.roleNames.admin) || this.get('currentUser').hasPermission(_permissionUtils.roleNames.super)) {
                this.get('store').findAll('user').then(function (result) {
                    _this.set('viewData.users', result);

                    // if user id set, find user email and set to user
                    if (operator && operator.get('userId')) {
                        var found = _this.get('viewData.users').find(function (user) {
                            return user.get('id') === operator.get('userId');
                        });
                        if (found) {
                            operator.set('userIdEmail', found.get('email'));
                        }
                    }
                });
            } else {
                operator.set('userIdEmail', operator.get('userEmail'));
            }

            return this.get('viewData');
        },


        actions: {
            selectUser: function selectUser(dropdown, user) {
                dropdown.actions.close();

                this.set('viewData.operator.userId', user ? user.get('id') : null);
                this.set('viewData.operator.userIdEmail', user ? user.get('email') : null); // showing in input
                this.set('viewData.operator.userEmail', user ? user.get('email') : null); // set for DB information
            }
        },

        filterObserver: Ember.observer('viewData.users.@each.id', 'viewData.filterText', function () {
            var _this2 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredUsers', this.get('viewData.users').sortBy('email').filter(function (i) {
                return _this2._filterUser(i, filter);
            }));
        }),

        _filterUser: function _filterUser(user, filter) {
            return ('' + user.get('name')).toLowerCase().includes(filter) || ('' + user.get('email')).toLowerCase().includes(filter);
        }
    });
});