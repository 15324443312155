define('garage/components/address-complete/component', ['exports', 'garage/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var _this = this;

            var elem = this.$('input[type="search"]');
            var cache = {};

            elem.autocomplete({
                autoFocus: true,
                minLength: 3,
                delay: 500,
                source: function source(input, cb) {
                    if (input.term in cache) {
                        return cb(cache[input.term]);
                    }

                    Ember.$.get(_environment.default.APP.geo.host + '/search/2/geocode/' + input.term + '.json?countrySet=' + _environment.default.APP.geo.countrySet + '&key=' + _environment.default.APP.geo.key).then(function (response) {
                        var result = response.results.map(function (i) {
                            return {
                                label: i.type + ': ' + i.address.freeformAddress,
                                value: i.type + ': ' + i.address.freeformAddress,
                                data: i
                            };
                        });

                        cache[input.term] = result;

                        cb(result);
                    }).catch(function (err) {
                        return Ember.Logger.error('auto-complete failed to query the API', err);
                    });
                },

                select: function select(event, ui) {
                    _this.get('selected')(ui.item.data);
                }
            });

            elem.keydown(function (event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    return false;
                }
            });
        }
    });
});