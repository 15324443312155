define('garage/helpers/json', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.jsonize = jsonize;


    /**
     * Show arguments as JSON.
     */
    function jsonize() {
        var ret = '';

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        args[0].forEach(function (arg) {
            if (ret !== '') {
                ret += ', ';
            }
            ret += JSON.stringify(arg);
        });

        return '[' + ret + ']';
    }

    exports.default = Ember.Helper.helper(jsonize);
});