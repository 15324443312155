define('garage/dispatch-area/transform', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            return serialized ? JSON.stringify(serialized).replace(/"/g, '').replace('[', '').replace(']', '') : serialized;
        },
        serialize: function serialize(deserialized) {
            return deserialized ? deserialized.replace(/(^\s*,)|(,\s*$)/g, '').split(',') : null;
        }
    });
});