define('garage/operator/service', ['exports', 'papaparse', 'garage/config/environment'], function (exports, _papaparse, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        modalMessage: Ember.inject.service(),

        parseFile: function parseFile(file) {
            return new Promise(function (res, rej) {
                return _papaparse.default.parse(file, {
                    header: true,
                    encoding: _environment.default.APP.import.encoding,
                    complete: res,
                    error: rej
                });
            });
        },
        parse: function parse(file) {
            var _this = this;

            return this.parseFile(file).then(function (parsed) {
                return _this.handleParsedData(parsed.data);
            }).catch(function (e) {
                return console.log(e) || _this.get('modalMessage').showDialog('Import Interrupted', e.message);
            });
        },
        handleParsedData: function handleParsedData() {
            var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

            var dups = {};

            items = items.map(function (item) {
                // trim all strings
                Object.keys(item).filter(function (i) {
                    return typeof item[i] === 'string';
                }).forEach(function (i) {
                    return item[i] = item[i].trim() || null;
                });

                var _ref = [item.USER_EMAIL, item.COMPANY_NAME, item.COMPANY_ADDRESS, item.CONTACT_NAME, item.CONTACT_PHONE, item.CONTACT_EMAIL, item.BUSINESS_ID, item.EXTERNAL_ID, item.INFO],
                    userEmail = _ref[0],
                    companyName = _ref[1],
                    companyAddress = _ref[2],
                    contactName = _ref[3],
                    contactPhone = _ref[4],
                    contactEmail = _ref[5],
                    businessId = _ref[6],
                    externalId = _ref[7],
                    info = _ref[8];


                if (businessId) {
                    dups[businessId] = (dups[businessId] || 0) + 1;
                }

                return { userEmail: userEmail, companyName: companyName, companyAddress: companyAddress, contactName: contactName, contactPhone: contactPhone, contactEmail: contactEmail, businessId: businessId, externalId: externalId, info: info };
            });

            if (Object.values(dups).find(function (i) {
                return i > 1;
            })) {
                throw new Error('Conflict! Operators with matching business ids found! Business id should be unique!');
            }

            return items;
        }
    });
});