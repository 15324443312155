define('garage/vehicle/route', ['exports', 'garage/config/environment', 'garage/mixins/restricted-route-mixin', 'garage/helpers/permission-utils'], function (exports, _environment, _restrictedRouteMixin, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NEW_PREFIX = 'new';

    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),

        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            vehicles: [],
            selected: null,
            canImport: false,
            hasDirty: false,
            savingAll: false,
            search: ''
        }),

        model: function model() {
            var _this = this;

            this.set('viewData.canImport', _environment.default.APP.import.enabled); // enable import based on configuration

            return this.get('store').findAll('vehicle').then(function () {
                return _this.loadVehiclesToViewData().then(function () {
                    return _this.get('viewData');
                });
            });
        },
        loadVehiclesToViewData: function loadVehiclesToViewData() {
            var _this2 = this;

            return Promise.resolve(this.get('store').peekAll('vehicle')).then(function (result) {
                var vehicles = result.sortBy('number');
                _this2.set('viewData.vehicles', vehicles);
                _this2.set('viewData.hasDirty', vehicles.any(function (v) {
                    return v.get('hasDirtyAttributes');
                }));
                return vehicles;
            });
        },
        draftVehicle: function draftVehicle() {
            var vehicle = this.get('store').createRecord('vehicle');
            vehicle.set('id', this.getNextNewId());
            vehicle.set('name', 'New Vehicle');
            return vehicle;
        },
        addVehicle: function addVehicle() {
            var vehicle = this.get('store').createRecord('vehicle');
            vehicle.set('id', this.getNextNewId());
            vehicle.set('name', 'New Vehicle');
            return vehicle;
        },
        saveAll: function saveAll() {
            var _this3 = this;

            var unsavedVehicles = this.get('store').peekAll('vehicle').filterBy('hasDirtyAttributes', true);
            var success = [];
            var failed = [];
            var chain = Promise.resolve();
            this.set('viewData.savingAll', true);

            var _loop = function _loop(i) {
                var vehicle = unsavedVehicles[i];
                if (vehicle.get('id') && vehicle.get('id').startsWith(NEW_PREFIX)) {
                    vehicle.set('id', null);
                }
                chain = chain.then(function () {
                    return vehicle.save().then(function () {
                        success.push(vehicle.get('number'));
                    }).catch(function (e) {
                        failed.push('\n\r' + vehicle.get('number') + ' (' + e.errors[0].title + ')');
                    }).catch(function (e) {
                        failed.push('\n\r' + vehicle.get('number') + '(' + e.message + ')');
                    });
                });
            };

            for (var i = 0; i < unsavedVehicles.length; i++) {
                _loop(i);
            }
            chain.then(function () {
                _this3.set('viewData.savingAll', false);
                var title = _this3.get('i18n').t('dialog.saved.title');
                var text = _this3.get('i18n').t('dialog.saved.text.vehicle', {
                    success: success.join(', ') || '-',
                    totalSuccess: success.length,
                    failed: failed.join(', ') || '-'
                });
                _this3.get('modalMessage').showDialog(title, text);
                _this3.refresh();
            });
        },
        getNextNewId: function getNextNewId() {
            // find highest new number
            var allVehicles = this.get('store').peekAll('vehicle');
            var highestId = allVehicles.reduce(function (prev, v) {
                var currId = v.get('id');
                if (currId && currId.startsWith(NEW_PREFIX)) {
                    var components = currId.split('-');
                    var number = parseInt(components[1], 10);
                    prev = isNaN(number) ? prev : number;
                }
                return prev;
            }, 0);
            return NEW_PREFIX + '-' + ++highestId;
        },


        actions: {
            search: function search() {
                var filter = this.get('viewData.search').toLowerCase();
                this.get('viewData.vehicles').forEach(function (vehicle) {
                    var name = (vehicle.get('name') || '').toLowerCase();
                    var number = '' + (vehicle.get('number') || '');
                    var plate = (vehicle.get('licenceNumber') || '').toLowerCase();

                    vehicle.set('filtered', filter && name.indexOf(filter) < 0 && number.indexOf(filter) < 0 && plate.indexOf(filter) < 0);
                });
            },
            selectVehicle: function selectVehicle(vehicle) {
                // search current route name to maintain selected tab with the new selected vehicle
                var route = Ember.getOwner(this).lookup('controller:application').currentRouteName;
                this.set('viewData.selected', vehicle);
                if (route === 'vehicle.index' || route === 'vehicle.import') {
                    route = 'vehicle.vehicle.index';
                }
                this.transitionTo(route, vehicle.id);
            },
            deleteVehicle: function deleteVehicle(vehicle) {
                var _this4 = this;

                var title = this.get('i18n').t('dialog.delete.title', { name: vehicle.get('name') });
                var text = this.get('i18n').t('dialog.delete.text');
                this.get('modalMessage').confirm(title, text, function () {
                    vehicle.deleteRecord();
                    vehicle.save().then(function () {
                        _this4.transitionTo('vehicle.index');
                        title = _this4.get('i18n').t('message.title.success');
                        text = _this4.get('i18n').t('message.text.vehicle.delete.success');
                        _this4.get('modalMessage').showDialog(title, text);
                        _this4.refresh();
                    }, function () {
                        title = _this4.get('i18n').t('message.title.failure');
                        text = _this4.get('i18n').t('message.text.vehicle.delete.failure');
                        _this4.get('modalMessage').showDialog(title, text);
                    });
                });
            },
            copyVehicle: function copyVehicle(vehicle) {
                var copy = vehicle.copy();
                copy.set('id', this.getNextNewId());
                this.transitionTo('vehicle.vehicle.index', copy.get('id'));
            },
            newVehicle: function newVehicle() {
                var vehicle = this.draftVehicle();
                this.transitionTo('vehicle.vehicle.index', vehicle.get('id'));
            },
            addVehicle: function addVehicle(json) {
                var store = this.get('store');
                var vehicle = store.hasRecordForId('vehicle', json.number) ? store.peekRecord('vehicle', json.number) : this.draftVehicle();
                vehicle.setProperties(json);
                this.loadVehiclesToViewData();
            },
            saveAll: function saveAll() {
                this.saveAll();
            },
            clearUnsaved: function clearUnsaved() {
                // En jumalauta kemsinyt miten emberissä saa hienosti rollbäckattyä dirty recordit
                // unloadAll toimi jossain tilanteissa mutta joskus tuli poikkeuksia mitä ei tietenkään
                // mitenkään pysty helposti debuggaan. Ember data on susi tuote.
                window.location.reload(true);
            },


            // receives id as parameter
            vehicleSaved: function vehicleSaved() {
                this.refresh();
            }
        }
    });
});