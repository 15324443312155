define('garage/capacity-unit/model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        value: _emberData.default.attr('number'),
        loadTime: _emberData.default.attr('number'),
        unloadTime: _emberData.default.attr('number')
    });
});