define('garage/components/vinka-sort-sign/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        isMainSort: Ember.computed('columnSorters', function () {
            var sorters = this.get('columnSorters');
            var column = this.get('column');
            if (sorters && sorters.length > 0) {
                return column.name === sorters[0].fn.column;
            }
            return false;
        }),

        sortDirCls: Ember.computed('columnSorters', function () {
            var sorters = this.get('columnSorters');
            var column = this.get('column');
            if (column && sorters) {
                var exists = sorters.find(function (s) {
                    return s.fn.column === column.name;
                });
                if (exists) {
                    return exists.direction === 1 ? 'fa-sort-asc' : 'fa-sort-desc';
                }
            }
            return 'fa-sort';
        }),

        actions: {
            sortCol: function sortCol() {
                this.get('sortCol')();
            }
        }
    });
});