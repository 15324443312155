define('garage/shift-template/model', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        vehicle: _emberData.default.attr('string'),
        startClock: _emberData.default.attr('string'),
        startDay: _emberData.default.attr('string'),
        startTime: _emberData.default.attr('date'),
        endClock: _emberData.default.attr('string'),
        endDay: _emberData.default.attr('string'),
        endTime: _emberData.default.attr('date'),

        startTimeClock: Ember.computed('startTime', 'startClock', function () {
            return (/^([01]\d|2[0-3]:[0-5]\d$)/.test(this.get('startClock')) ? this.get('startClock') : this._getClockTime(this.get('startTime'))
            );
        }),

        endTimeClock: Ember.computed('endTime', 'endClock', function () {
            return (/^([01]\d|2[0-3]:[0-5]\d$)/.test(this.get('endClock')) ? this.get('endClock') : this._getClockTime(this.get('endTime'))
            );
        }),

        startTimeDate: Ember.computed('startTime', function () {
            return this.get('startTime').toLocaleDateString();
        }),

        endTimeDate: Ember.computed('endTime', function () {
            return this.get('endTime').toLocaleDateString();
        }),

        startDatePicker: Ember.computed('startTime', function () {
            return (0, _moment.default)(this.get('startTime')).format('YYYY-MM-DD');
        }),

        endDatePicker: Ember.computed('endTime', function () {
            return (0, _moment.default)(this.get('endTime')).format('YYYY-MM-DD');
        }),

        startTimePicker: Ember.computed('startTime', 'startClock', function () {
            return (/^([01]\d|2[0-3]:[0-5]\d$)/.test(this.get('startClock')) ? this.get('startClock') : (0, _moment.default)(this.get('startTime')).format('HH:mm')
            );
        }),

        endTimePicker: Ember.computed('endTime', 'endClock', function () {
            return (/^([01]\d|2[0-3]:[0-5]\d$)/.test(this.get('endClock')) ? this.get('endClock') : (0, _moment.default)(this.get('endTime')).format('HH:mm')
            );
        }),

        setStartTime: function setStartTime(uiDate, time) {
            this.set('startTime', this._createDate(uiDate, time));
            this.set('startClock', this._createClock(this.get('startTime')));
        },
        setEndTime: function setEndTime(uiDate, time) {
            this.set('endTime', this._createDate(uiDate, time));
            this.set('endClock', this._createClock(this.get('endTime')));
        },
        _createDate: function _createDate(uiDate, time) {
            var timeComps = time.split(':');
            timeComps = timeComps.length > 1 ? timeComps : time.split('.');
            var hour = parseInt(timeComps[0], 10); // Todo:
            var minute = parseInt(timeComps[1], 10);
            return (0, _moment.default)(uiDate).set('hour', hour).set('minute', minute).toDate();
        },
        _createClock: function _createClock(date) {
            return (0, _moment.default)(date).format('HH:mm');
        },
        _getClockTime: function _getClockTime(d) {
            var timeopts = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false };
            return d.toLocaleTimeString(timeopts).substring(0, 5);
        }
    });
});