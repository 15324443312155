define('garage/operator/operator/route', ['exports', 'garage/helpers/api-error-handler'], function (exports, _apiErrorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var NEW_PREFIX = 'new';

    exports.default = Ember.Route.extend({

        modalMessage: Ember.inject.service(),
        i18n: Ember.inject.service(),
        viewData: Ember.Object.create({
            tabs: [{ name: 'tab.Info', link: 'operator.operator.index' }, { name: 'tab.Vehicles', link: 'operator.operator.vehicle' }, { name: 'tab.Drivers', link: 'operator.operator.driver' }, { name: 'tab.VehicleReports', link: 'operator.operator.report' }, { name: 'tab.OperatorReports', link: 'operator.operator.reports' }],
            operator: null,
            showSaveButtons: true
        }),

        model: function model(_ref) {
            var _this = this;

            var id = _ref.id;


            if (id.startsWith(NEW_PREFIX)) {
                // new unsaved
                var allOperators = this.get('store').peekAll('operator');
                var newItem = allOperators.find(function (v) {
                    return v.get('id') === id;
                });
                if (!newItem) {
                    // Handle invalid ID.
                    this.transitionTo('operator.index');
                }
                this.set('viewData.operator', newItem);
                this.modelFor('operator').set('selected', newItem);
                return this.get('viewData');
            } else {
                // existing saved record
                return this.get('store').findRecord('operator', id).then(function (v) {
                    _this.set('viewData.operator', v);
                    _this.modelFor('operator').set('selected', v);
                    return _this.get('viewData');
                }).catch(function () {
                    // Handle invalid ID.
                    _this.transitionTo('operator.index');
                });
            }
        },


        actions: {
            didTransition: function didTransition() {
                // solution to manage showing save buttons
                // Showing save buttons by default when current or sub route changes. Enables shift sub-route to hide buttons by sending event.
                // If sub route would override didTransition it should either manage button showing by events or
                // bubble this didTransition by returning true.
                this.set('viewData.showSaveButtons', true);
            },
            hideSaveButtons: function hideSaveButtons() {
                this.set('viewData.showSaveButtons', false);
            },
            save: function save() {
                var _this2 = this;

                this.get('modalMessage').show(this.get('i18n').t('message.title.saving'), this.get('i18n').t('message.text.wait'));

                var operator = this.get('viewData.operator');
                var originalId = operator.get('id');
                if (originalId.startsWith(NEW_PREFIX)) {
                    operator.set('id', null);
                }
                operator.save().then(function (result) {
                    _this2.get('modalMessage').remove();
                    if (originalId.startsWith(NEW_PREFIX)) {
                        // change route to match created id in url
                        _this2.transitionTo('operator.operator.index', result.get('id'));
                    }
                    _this2.send('operatorSaved', result.get('id'));
                }, function (err) {
                    // failed
                    if (originalId.startsWith(NEW_PREFIX)) {
                        operator.set('id', originalId);
                    }
                    _this2.get('modalMessage').remove();
                    var title = _this2.get('i18n').t('message.title.failure');
                    var text = _this2.get('i18n').t('message.text.operator.save.failure');
                    text = (0, _apiErrorHandler.formatApiError)(err, _this2.get('i18n'), 'operator');
                    _this2.get('modalMessage').showDialog(title, text);
                });
            },
            revert: function revert() {
                var _this3 = this;

                var title = this.get('i18n').t('dialog.revert.title');
                var text = this.get('i18n').t('dialog.revert.text');
                this.get('modalMessage').confirm(title, text, function () {
                    _this3.get('viewData.operator').rollbackAttributes();
                });
            }
        }
    });
});