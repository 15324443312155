define('garage/helpers/api-error-handler', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formatApiError = formatApiError;
    //  -*- coding: utf-8 -*-
    //  app/helpers/api-error-handler.js
    //  created: 2019-04-11T17:18:14
    //

    function formatApiError(error, i18n) {
        var category = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

        var text = null;
        var err = null;
        if (error && error.errors && error.errors.length) {
            err = error.errors[0];
        }
        if (err && err.validation && err.validation.keys && err.validation.keys.length) {
            var cat = category ? category + '.' : '';
            var key = 'messages.' + cat + err.validation.keys[0];
            if (i18n.exists(key)) {
                text = i18n.t(key);
            }
        }
        if (text === null) {
            text = err.message;
        }
        return text ? text : '';
    }
});