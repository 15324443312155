define('garage/components/vinka-menu/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            setLocale: function setLocale(locale) {
                var onLocale = this.get('onLocale');

                if (onLocale) {
                    onLocale(locale);
                }
            }
        }
    });
});