define('garage/components/vinka-toggle/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        i18n: Ember.inject.service(),

        isChecked: false,

        init: function init() {
            this._super.apply(this, arguments);
            this.set('isChecked', this.get('current'));
            this.set('label', this.get('i18n').t('toggle.' + this.get('name')).string || 'Translation missing: ' + 'toggle.' + this.get('name'));
            if (this.get('disabled')) {
                this.set('isDisabled', true);
            }
        },


        currentChanged: Ember.observer('current', function (state) {
            this.set('isChecked', state.get('current'));
        }),

        disabledChanged: Ember.observer('disabled', function (state) {
            this.set('isDisabled', state.get('disabled'));
        }),

        actions: {
            click: function click() {
                var state = !this.get('isChecked');
                this.set('isChecked', state);
                this.set('current', state);
                var toggleFn = this.get('onToggle');
                if (toggleFn) {
                    toggleFn(state);
                }
            }
        }
    });
});