define('garage/current-user/service', ['exports', 'ember-simple-auth-token/authenticators/jwt', 'garage/helpers/permission-utils'], function (exports, _jwt, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _defineProperty(obj, key, value) {
        if (key in obj) {
            Object.defineProperty(obj, key, {
                value: value,
                enumerable: true,
                configurable: true,
                writable: true
            });
        } else {
            obj[key] = value;
        }

        return obj;
    }

    exports.default = Ember.Service.extend({
        session: Ember.inject.service(),

        // TODO: make sure that permissions are updated when token changes!
        permissions: Ember.computed('session.data.authenticated.accessToken', function () {
            var token = this.get('session.data.authenticated.accessToken');

            if (token) {
                var jwt = new _jwt.default();
                var tokenData = jwt.getTokenData(token);

                return tokenData.permissions.reduce(function (a, i) {
                    return Object.assign(a, _defineProperty({}, i, 1));
                }, {});
            }

            return {};
        }),

        getLandingPage: function getLandingPage(def) {
            if (this.hasPermission(_permissionUtils.roleNames.admin) || this.hasPermission(_permissionUtils.roleNames.super)) {
                return 'vehicle';
            } else if (this.hasPermission(_permissionUtils.roleNames.operator)) {
                return 'operator';
            }

            return def;
        },
        hasPermission: function hasPermission(permission) {
            return this.get('permissions').hasOwnProperty(permission);
        },
        isPlainOperator: function isPlainOperator() {
            var _this = this;

            var convertedOperatorPerms = (0, _permissionUtils.convertToSiteRoles)(['role:operator']);
            var convertedAdminPerms = (0, _permissionUtils.convertToSiteRoles)(['role:admin', 'role:super']);
            var isOperator = convertedOperatorPerms.some(function (i) {
                return _this.hasPermission(i);
            });
            return !isOperator ? false : !convertedAdminPerms.some(function (i) {
                return _this.hasPermission(i);
            });
        }
    });
});