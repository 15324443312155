define('garage/components/import-data/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        actions: {
            selectImportedItem: function selectImportedItem(item) {
                this.selectImportedItem(item);
            },
            saveAll: function saveAll() {
                this.saveAll();
            },
            clear: function clear() {
                this.clearUnsaved();
                this.refresh();
            }
        }
    });
});