define('garage/login/route', ['exports', 'garage/config/environment', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _environment, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

        session: Ember.inject.service('session'),

        loginDialog: function loginDialog() {
            var lockOptions = {
                auth: {
                    audience: _environment.default['ember-simple-auth'].auth0.audience,
                    params: {
                        scope: 'openid user_metadata',
                        responseType: 'token id_token'
                    }
                },

                theme: {
                    logo: 'vinka.png',
                    primaryColor: 'gray'
                },

                languageDictionary: {
                    title: 'Garage'
                },

                closable: false,
                allowSignUp: _environment.default.APP.auth0.allowSignUp,
                autoclose: true,
                rememberLastLogin: false,
                allowedConnections: ['Username-Password-Authentication', 'google-oauth2']
            };

            this.get('session').authenticate('authenticator:auth0-lock', lockOptions);
        },


        actions: {
            login: function login() {
                this.loginDialog();
            }
        }
    });
});