define("garage/mirage/fixtures/shift-groups", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    "id": 76395, type: "shift-group",
    "attributes": {
      "name": "weekend",
      "templates": [{
        "id": 60837,
        "startDay": "Friday",
        "startTime": "12:00",
        "endDay": "Friday",
        "endTime": "14:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 65716,
        "startDay": "Friday",
        "startTime": "08:00",
        "endDay": "Friday",
        "endTime": "13:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }]
    }
  }, {
    "id": 35430, type: "shift-group",
    "attributes": {
      "name": "morning",
      "templates": [{
        "id": 27317,
        "startDay": "Monday",
        "startTime": "07:00",
        "endDay": "Monday",
        "endTime": "14:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 57435,
        "startDay": "Sunday",
        "startTime": "07:00",
        "endDay": "Sunday",
        "endTime": "13:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 592,
        "startDay": "Saturday",
        "startTime": "09:00",
        "endDay": "Saturday",
        "endTime": "17:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 72130,
        "startDay": "Friday",
        "startTime": "07:00",
        "endDay": "Friday",
        "endTime": "18:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }]
    }
  }, {
    "id": 53770, type: "shift-group",
    "attributes": {
      "name": "evening",
      "templates": [{
        "id": 16420,
        "startDay": "Thursday",
        "startTime": "07:00",
        "endDay": "Thursday",
        "endTime": "21:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 44311,
        "startDay": "Thursday",
        "startTime": "11:00",
        "endDay": "Thursday",
        "endTime": "19:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }, {
        "id": 77539,
        "startDay": "Tuesday",
        "startTime": "12:00",
        "endDay": "Tuesday",
        "endTime": "15:00",
        "from": "2016-01-01",
        "to": "2016-12-31"
      }]
    }
  }];
});