define('garage/components/capacity-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['capacity-row'],
        capacityInputs: null,
        capacityConfigs: null,

        init: function init() {
            this._super.apply(this, arguments);
            if (this.get('capacityConfigs') === null) {
                this.set('capacityConfigs', []);
            }
        },
        _isAddMode: function _isAddMode() {
            return !!this.get('addCapacityRow');
        },
        _initInputs: function _initInputs() {
            var _this = this;

            var items = this.get('capacityOptions').map(function (i) {
                return Ember.Object.create({
                    value: _this._getCapacityValue(i.id),
                    id: i.id
                });
            });
            this.set('capacityInputs', items);
        },
        _getCapacityValue: function _getCapacityValue(id) {
            var caps = this.get('capacityConfigs');
            var found = caps.find(function (i) {
                return i.unitId === id;
            });
            if (found) {
                return found.count;
            }
            return 0;
        },
        didReceiveAttrs: function didReceiveAttrs() {
            this._initInputs();
        },


        actions: {
            capacityChanged: function capacityChanged() {
                var inputs = this.get('capacityInputs');
                var capacityConfigs = this.get('capacityConfigs');
                var newCapacities = [];
                var hasChanges = false;

                // compare if something changed, if is: update values and send event for marking changed
                inputs.forEach(function (i) {
                    var val = parseInt(i.value, 10);
                    val = !isNaN(val) ? val : 0;

                    if (val > 0) {
                        newCapacities.push({ unitId: i.id, count: val });
                    }

                    var foundIndex = capacityConfigs.findIndex(function (c) {
                        return c.unitId === i.id;
                    });
                    if (foundIndex !== -1 && capacityConfigs[foundIndex].count !== val) {
                        // old value changed (modify or remove) -> update
                        hasChanges = true;
                    } else if (foundIndex === -1 && i.value > 0) {
                        // add
                        hasChanges = true;
                    }
                });
                if (hasChanges && !this._isAddMode()) {
                    // notify with old, new values
                    this.get('capacitiesChanged')(capacityConfigs, newCapacities);
                }
            },
            removeThis: function removeThis() {
                this.get('removeCapacityRow')(this.get('capacityConfigs'));
            },
            addThis: function addThis() {
                var inputs = this.get('capacityInputs');
                var newCapacities = [];
                inputs.forEach(function (i) {
                    var val = parseInt(i.value, 10);
                    val = !isNaN(val) ? val : 0;

                    if (val > 0) {
                        newCapacities.push({ unitId: i.id, count: val });
                    }
                });
                this.get('addCapacityRow')(newCapacities);

                // revert this form
                this.set('capacityConfigs', []);
                this._initInputs();
            }
        }
    });
});