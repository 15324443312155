define('garage/components/vinka-button/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /**
     * A button with automatic translation and disabling.
     *
     * Example:
     *
     * {{vinka-button "Name" model.buttonOn}}
     *
     * Title is automatically translated from `button.name` string.
     * If second argument changes, the it enables when it turns to true.
     * Additional argument `style` is used as CSS class for the button if given.
     * Handler action can be defined as `onClick` action attribute.
     */
    var Button = Ember.Component.extend({

        title: null,

        i18n: Ember.inject.service(),

        disabled: Ember.computed('params.@each', function () {
            return !this.get('params') || !this.get('params')[1];
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this.set('title', this.get('i18n').t('button.' + this.get('params')[0]).string);
            var style = this.get('style');
            if (!style) {
                style = 'btn btn-secondary pull-left';
            }
            this.set('style', style);
        },


        actions: {
            buttonClick: function buttonClick() {
                var onClick = this.get('onClick');
                if (onClick) {
                    onClick();
                }
            }
        }
    });

    Button.reopenClass({
        positionalParams: 'params'
    });

    exports.default = Button;
});