define('garage/serializers/user', ['exports', 'garage/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function normalizeUser(u) {
        return {
            type: 'user',
            id: '' + u.id,
            attributes: u
        };
    }

    exports.default = _application.default.extend({
        normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload /*, id, requestType*/) {
            payload.data = payload.data.map(normalizeUser);

            return this._super.apply(this, arguments);
        },
        normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload /*, id, requestType*/) {
            payload.data = normalizeUser(payload.data);

            return this._super.apply(this, arguments);
        }
    });
});