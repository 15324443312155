define('garage/driver/model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(Ember.Copyable, {
        name: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        pinCode: _emberData.default.attr('string'),
        operator: _emberData.default.attr('number'),
        operatorName: _emberData.default.attr('string'),
        licenseAccepted: _emberData.default.attr('boolean'),
        status: _emberData.default.attr('string'),
        externalId: _emberData.default.attr('string'),

        isUnsaved: Ember.computed('id', function () {
            return this.get('id') && this.get('id').startsWith('new');
        }),

        copy: function copy() {
            return this.get('store').createRecord('driver', {
                name: 'Copy of ' + this.get('name'),
                phone: this.get('phone'),
                email: this.get('email'),
                pinCode: this.get('pinCode'),
                operator: this.get('operator'),
                licenseAccepted: false,
                status: this.get('status'),
                externalId: this.get('externalId')
            });
        }
    });
});