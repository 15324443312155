define('garage/locales/fi/config', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {

        rtl: false,

        pluralForm: function pluralForm(count) {
            if (count === 0) {
                return 'zero';
            }
            if (count === 1) {
                return 'one';
            }
            return 'other';
        }
    };
});