define('garage/serializers/permission', ['exports', 'garage/serializers/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function normalizePermission(p) {
        return {
            type: 'permission',
            id: '' + p.id,
            attributes: p
        };
    }

    exports.default = _application.default.extend({
        normalizeArrayResponse: function normalizeArrayResponse(store, primaryModelClass, payload /*, id, requestType*/) {
            payload.data = payload.data.map(normalizePermission);

            return this._super.apply(this, arguments);
        },
        normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload /*, id, requestType*/) {
            payload.data = normalizePermission(payload.data);

            return this._super.apply(this, arguments);
        }
    });
});