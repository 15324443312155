define('garage/operator/operator/report/route', ['exports', 'moment', 'garage/config/environment'], function (exports, _moment, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var formatCurrency = function formatCurrency(value) {
        var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'EUR';
        var locale = arguments[2];

        return Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
    };

    var formatRouteFee = function formatRouteFee(fees, feeName, currency, locale) {
        var found = (fees || []).find(function (f) {
            return f.name === feeName;
        });
        return found ? formatCurrency(found.fee, currency, locale) : '';
    };

    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),
        exporter: Ember.inject.service(),

        viewData: Ember.Object.create({
            filterText: '',
            vehicles: Ember.A([]),
            filteredVehicles: [],
            selectedVehicle: null,
            startTime: null,
            endTime: null,
            report: null
        }),

        exportFileName: '',

        resetUi: function resetUi() {
            this.set('viewData.report', null);
            this.set('viewData.filterText', '');
            this.set('viewData.filteredVehicles', []);
            this.set('viewData.selectedVehicle', null);
            this.set('viewData.showExtraRouteReportColumns', _environment.default.APP.ui.showExtraRouteReportColumns);
        },
        model: function model() {
            var _this = this;

            this.resetUi();

            var operatorId = +this.modelFor('operator.operator').operator.get('id');

            console.log(this.modelFor('operator.operator').operator.get('id'));

            this.get('store').findAll('vehicle').then(function (result) {
                // filter by operator id because admins receive ALL the vehicles.
                _this.set('viewData.vehicles', result.filter(function (i) {
                    return i.get('operator') === operatorId;
                }));
                _this.set('viewData.filteredVehicles', _this.get('viewData.vehicles').filter(Boolean));
            });

            return this.get('viewData');
        },


        actions: {
            didTransition: function didTransition() {
                // send event to hide save buttons as those are not needed in this view
                this.send('hideSaveButtons');
            },
            selectVehicle: function selectVehicle(dropdown, vehicle) {
                dropdown.actions.close();

                this.set('viewData.selectedVehicle', vehicle);

                this.set('viewData.filterText', '');
            },
            generateReport: function generateReport() {
                var _this2 = this;

                this.set('viewData.report', null);

                if (!this.get('viewData.selectedVehicle') || !this.get('viewData.startTime') || !this.get('viewData.endTime')) {
                    return;
                }

                var locale = this.get('i18n.locale');
                var startTime = (0, _moment.default)(this.get('viewData.startTime')).startOf('day');
                var endTime = (0, _moment.default)(this.get('viewData.endTime')).endOf('day');

                this.exportFileName = this.get('viewData.selectedVehicle.number') + '-route-history-' + startTime.format('YYYY_MM_DD') + '-' + endTime.format('YYYY_MM_DD');

                this.get('viewData.selectedVehicle').genReport({
                    type: 'route-history',
                    startTime: startTime.toISOString(),
                    endTime: endTime.toISOString()
                }).then(function (result) {
                    _this2.set('viewData.report', result.data.map(function (r) {
                        r.date = (0, _moment.default)(r.date).locale(locale).format('L');
                        r.startTime = r.startTime ? (0, _moment.default)(r.startTime).locale(locale).format('LT') : '';
                        r.endTime = r.endTime ? (0, _moment.default)(r.endTime).locale(locale).format('LT') : '';
                        r.distance = r.distance ? (r.distance / 1000).toFixed(1) : 0;

                        // format all currency values
                        r.total = r.total ? formatCurrency(r.total, r.currency, locale) : '';
                        r.excess = r.excess ? formatCurrency(r.excess, r.currency, locale) : '';
                        r.extraFees = r.extraFees ? formatCurrency(r.extraFees, r.currency, locale) : '';
                        r.vat = r.vat ? formatCurrency(r.vat, r.currency, locale) : '';
                        r.waitFee = formatRouteFee(r.routeFees, 'wait', r.currency, locale);
                        r.transitFee = formatRouteFee(r.routeFees, 'transit', r.currency, locale);

                        return r;
                    }));
                });
            },
            toExcel: function toExcel() {
                //this.get('exporter').toExcel(this.exportFileName, 'worksheet', 'route-history');
                this.get('exporter').toCSV(this.exportFileName, 'route-history');
            }
        },

        filterObserver: Ember.observer('viewData.filterText', function () {
            var _this3 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredVehicles', this.get('viewData.vehicles').filter(function (i) {
                return _this3._filterVehicle(i, filter);
            }));
        }),

        _filterVehicle: function _filterVehicle(vehicle, filter) {
            return ('' + vehicle.get('name')).toLowerCase().includes(filter) || ('' + vehicle.get('phone_number')).toLowerCase().includes(filter) || ('' + vehicle.get('number')).toLowerCase().includes(filter);
        }
    });
});