define('garage/vehicle/import/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        mbrVehiclesParser: Ember.inject.service('mbr-vehicles-parser'),
        hasDirty: false,
        vehicles: Ember.Object.create([]),
        viewData: Ember.Object.create({
            vehFile: null,
            capFile: null,
            vehicleJsonFile: null
        }),

        model: function model() {
            var vehicles = this.modelFor('vehicle').vehicles;
            this.set('vehicles', vehicles.length ? vehicles.filterBy('hasDirtyAttributes', true) : []);
            this.set('hasDirty', this.modelFor('vehicle').hasDirty);
            return Ember.RSVP.hash({
                viewData: this.get('viewData'),
                hasDirty: this.get('hasDirty'),
                vehicles: this.get('vehicles')
            });
        },
        doUpload: function doUpload(file) {
            if (file.name.startsWith('mbr-vehicle')) {
                this.set('viewData.vehFile', file);
            }
            if (file.name.startsWith('mbr-capacity')) {
                this.set('viewData.capFile', file);
            }
            if (file.name.endsWith('json')) {
                this.set('viewData.vehicleJsonFile', file);
            }
        },


        actions: {
            dropped: function dropped(e) {
                var _this = this;

                e.preventDefault();
                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = e.dataTransfer.files[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var file = _step.value;

                        console.log('dropped file', file);
                        this.doUpload(file);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                var vehFile = this.get('viewData.vehFile');
                var capFile = this.get('viewData.capFile');
                if (vehFile && capFile) {
                    this.get('mbrVehiclesParser').parse(vehFile, capFile).then(function (vehicles) {
                        vehicles.forEach(function (vehicle) {
                            return _this.send('addVehicle', vehicle);
                        });
                        _this.refresh();
                    });
                }
                var vehicleJsonFile = this.get('viewData.vehicleJsonFile');
                if (vehicleJsonFile) {
                    var reader = new FileReader();
                    reader.onload = function (event) {
                        var vehicles = JSON.parse(event.target.result);
                        console.log('Vehicles:  +', vehicles);
                        vehicles.forEach(function (vehicle) {
                            return _this.send('addVehicle', vehicle);
                        });
                        _this.refresh();
                    };
                    reader.onerror = function (event) {
                        console.error('Vehicles file could not be read!\n                         Code ' + event.target.error.code);
                    };
                    reader.readAsText(vehicleJsonFile);
                }
            },
            selectImportedItem: function selectImportedItem(vehicle) {
                this.transitionTo('vehicle.vehicle.index', vehicle.id);
            },
            saveAllUnsaved: function saveAllUnsaved() {
                this.send('saveAll');
            },
            clearAllUnsaved: function clearAllUnsaved() {
                this.send('clearUnsaved');
            }
        }
    });
});