define('garage/vehicle/vehicle/attribute/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function attributesDiffer(newAttrIds, oldAttrs) {
        if (newAttrIds.length !== oldAttrs.length) {
            return true;
        }

        var hasChanges = false;
        newAttrIds.forEach(function (a) {
            var found = oldAttrs.find(function (o) {
                return o.toString() === a.toString();
            });
            if (!found) {
                hasChanges = true;
            }
        });

        return hasChanges;
    }

    var findAttributeLocalization = function findAttributeLocalization(locale, attribute) {
        if (attribute && attribute.get('i18n') && locale) {
            return attribute.get('i18n')[locale];
        }
        return undefined;
    };

    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),

        viewData: Ember.Object.create({
            attributeUnits: [],
            vehicleAttributes: [],
            vehicle: null
        }),

        model: function model() {
            var _this = this;

            this.get('store').findAll('attribute').then(function (result) {
                _this.set('viewData.attributeUnits', result);
                _this.initAttributes();
            });
            this.set('viewData.vehicle', this.modelFor('vehicle.vehicle').vehicle);
            return this.get('viewData');
        },
        initAttributes: function initAttributes() {
            var _this2 = this;

            var attrs = this.get('viewData.attributeUnits');
            var locale = this.get('i18n').locale;
            if (!attrs || attrs.length === 0) {
                // cannot set vehicleAttributes if there are no attributes
                return;
            }
            var existingAttrs = this.get('viewData.vehicle.attributes') || [];
            var attributeMappings = attrs.map(function (a) {
                // check if attribute in vehicle attributes
                var found = existingAttrs.find(function (e) {
                    return e.id.toString() === a.get('id').toString();
                });

                var attributeLoc = findAttributeLocalization(locale, a);
                var translationKey = 'form.attributes.' + a.get('name');
                var translationExists = _this2.get('i18n').exists(translationKey);
                var obj = Ember.Object.create({
                    id: a.id,
                    selected: !!found,
                    name: a.get('name'),
                    translation: attributeLoc ? attributeLoc : translationExists ? _this2.get('i18n').t(translationKey) : a.get('name')
                });
                return obj;
            });
            this.set('viewData.vehicleAttributes', attributeMappings);
        },


        attributeSelectionObserver: Ember.observer('viewData.vehicleAttributes.@each.selected', function () {
            var _this3 = this;

            if (this.get('viewData.vehicle')) {
                // if selected attributes have changed re-create vehicle's attribute array
                var selectedIds = this.getSelectedAttributesIds();
                var differ = this.isVehicleAttributesDifferent(selectedIds);

                if (differ) {
                    var newAttrs = selectedIds.map(function (id) {
                        var attrs = _this3.get('viewData.attributeUnits');
                        var found = attrs.find(function (a) {
                            return a.id.toString() === id.toString();
                        });
                        return { id: found.get('id'), name: found.get('name') };
                    });
                    // need to set whole array so that revert-button can actually revert set value later
                    this.get('viewData.vehicle').set('attributes', newAttrs);
                }
            }
        }),

        // return ids of the attributes that have been checked selected
        getSelectedAttributesIds: function getSelectedAttributesIds() {
            var selectedIds = this.get('viewData.vehicleAttributes').map(function (va) {
                if (va.get('selected')) {
                    return va.get('id');
                }
                return null;
            });
            selectedIds = selectedIds.filter(function (i) {
                return i !== null;
            });
            return selectedIds;
        },


        // compare if
        isVehicleAttributesDifferent: function isVehicleAttributesDifferent(selectedIds) {
            var existingAttrs = this.get('viewData.vehicle.attributes') || [];
            var existingIds = existingAttrs.map(function (a) {
                return a.id;
            });
            var differ = attributesDiffer(selectedIds, existingIds);
            return differ;
        },


        vehicleObserver: Ember.observer('viewData.vehicle.attributes', function () {
            var dirty = this.get('viewData.vehicle.hasDirtyAttributes');
            var selectedIds = this.getSelectedAttributesIds();
            if (!dirty && this.isVehicleAttributesDifferent(selectedIds)) {
                this.initAttributes();
                return;
            }
        })
    });
});