define('garage/vehicle/vehicle/terminal/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return {
                vehicle: this.modelFor('vehicle.vehicle').vehicle
            };
        }
    });
});