define('garage/operator/import/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        opService: Ember.inject.service('operator'),
        hasDirty: false,
        operators: Ember.Object.create([]),
        viewData: Ember.Object.create({
            operatorFile: null
        }),

        model: function model() {
            var operators = this.modelFor('operator').operators;
            this.set('operators', operators.length ? operators.filterBy('hasDirtyAttributes', true) : []);
            this.set('hasDirty', this.modelFor('operator').hasDirty);

            return Ember.RSVP.hash({
                viewData: this.get('viewData'),
                hasDirty: this.get('hasDirty'),
                operators: this.get('operators')
            });
        },
        doUpload: function doUpload(file) {
            if (file.name.startsWith('mbr-operator')) {
                this.set('viewData.operatorFile', file);
            }
        },


        actions: {
            dropped: function dropped(e) {
                var _this = this;

                e.preventDefault();

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    for (var _iterator = e.dataTransfer.files[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        var file = _step.value;

                        console.log('dropped file', file);
                        this.doUpload(file);
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }

                var operatorFile = this.get('viewData.operatorFile');

                if (operatorFile) {
                    this.get('opService').parse(operatorFile).then(function (operators) {
                        console.log(operators);

                        (operators || []).forEach(function (operator) {
                            return _this.send('addOperator', operator);
                        });
                        _this.refresh();
                    });
                }
            },
            selectImportedItem: function selectImportedItem(operator) {
                this.transitionTo('operator.operator.index', operator.id);
            },
            saveAllUnsaved: function saveAllUnsaved() {
                this.send('saveAll');
            },
            clearAllUnsaved: function clearAllUnsaved() {
                this.send('clearUnsaved');
            }
        }
    });
});