define('garage/shift-template/adapter', ['exports', 'garage/application/adapter'], function (exports, _adapter) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        _getVehicleId: function _getVehicleId(snapshot, query) {
            var vehicleId = snapshot && snapshot.adapterOptions ? snapshot.adapterOptions.vehicleId : // fetchAll case
            query.adapterOptions ? query.adapterOptions.vehicleId : ''; // query case
            return vehicleId;
        },


        /**
         * Modifies url to contain hierarchy by vehicle/shift. Extracts vehicle id from given parameters and builds url.
         * This adapts to all versions: fetchAll, save, query.
         * @todo: in future might be better to stick to more simple version of urls and not try any hierarchy.
         */
        buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
            var url = this._super.apply(this, arguments);
            var host = this.get('host');

            var vehicleId = this._getVehicleId(snapshot, query);
            if (query) {
                // query exists, delete custom given adapterOptions
                delete query.adapterOptions;
            }
            var newUrl = url;
            if (vehicleId) {
                newUrl = url.replace(host, host + ('/vehicles/' + vehicleId));
            } else {
                (true && !(false) && Ember.assert('Vehicle id not defined for shift-template adapter, you should set {adapterOptions: {vehicleId: id}}', false));
            }

            return newUrl;
        }
    });
});