define('garage/user/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        i18n: Ember.inject.service(),
        modalMessage: Ember.inject.service(),

        viewData: Ember.Object.create({
            newUser: Ember.Object.create({ email: '', password: '', permission: '', verified: true }),
            filterText: '',
            sortBy: 'email',
            users: [],
            filteredUsers: [],
            permissions: []
        }),

        model: function model() {
            var _this = this;

            this.get('store').findAll('user').then(function (result) {
                _this.set('viewData.users', result);
            });

            this.get('store').findAll('permission').then(function (result) {
                _this.set('viewData.permissions', result);
            });

            return this.get('viewData');
        },


        actions: {
            setUserPermission: function setUserPermission(value) {
                this.viewData.newUser.set('permission', value);
            },


            sortBy: function sortBy(newSortBy) {
                this.set('viewData.sortBy', newSortBy);
            },

            addUser: function addUser() {
                var _this2 = this;

                var user = this.get('store').createRecord('user', {
                    name: this.viewData.newUser.get('email'),
                    email: this.viewData.newUser.get('email'),
                    password: this.viewData.newUser.get('password'),
                    verified: this.viewData.newUser.get('verified'),
                    permissions: [this.viewData.newUser.get('permission')]
                });

                user.save().then(function (result) {
                    // display some custom comment if we received one from the API
                    if (result.get('comment')) {
                        _this2.get('modalMessage').showDialog(_this2.get('i18n').t('message.title.success'), result.get('comment'));
                    } else {
                        _this2.get('modalMessage').showDialog(_this2.get('i18n').t('message.title.success'), _this2.get('i18n').t('message.text.user.save.success'));
                    }

                    _this2.viewData.newUser.set('email', '');
                    _this2.viewData.newUser.set('password', '');
                    _this2.viewData.newUser.set('verified', true);
                    _this2.viewData.newUser.set('permission', '');

                    _this2.refresh();
                }).catch(function (error) {
                    console.log(error);
                    // failed to save the user. Remove this draft record from the database
                    user.destroyRecord();

                    // try to show the modal with an error.
                    _this2.get('modalMessage').showDialog(_this2.get('i18n').t('message.title.failure'),
                    //this.get('i18n').t('message.text.user.save.failure')
                    error.errors ? error.errors.map(function (i) {
                        return i.message;
                    }).join('; ') : error.message ? error.message : 'unknown error');
                });
            },
            removeUser: function removeUser(user) {
                // remove all users with the same email
                this.get('store').peekAll('user').filter(function (i) {
                    return i.get('email') === user.get('email');
                }).forEach(function (i) {
                    return i.destroyRecord().then(function (r) {
                        return r.unloadRecord();
                    });
                });

                //user.destroyRecord().then( rec => rec.unloadRecord());
            }
        },

        filterObserver: Ember.observer('viewData.users.@each.id', 'viewData.filterText', 'viewData.sortBy', function () {
            var _this3 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredUsers', this.get('viewData.users').sortBy(this.get('viewData.sortBy')).filter(function (i) {
                return _this3._filterUser(i, filter);
            }));
        }),

        _filterUser: function _filterUser(user, filter) {
            return ('' + user.get('name')).toLowerCase().includes(filter) || ('' + user.get('email')).toLowerCase().includes(filter);
        }
    });
});