define('garage/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.JSONAPISerializer.extend({

        /**
         * JSON API adapter expects properties to be in kebab-case. We don't want that.
         */
        keyForAttribute: function keyForAttribute(key) {
            return key;
        },


        /**
         * JSON API adapter expects properties to be in kebab-case. We don't want that.
         */
        keyForRelationship: function keyForRelationship(rawKey) {
            return rawKey;
        }
    });
});