define('garage/settings/route', ['exports', 'garage/mixins/restricted-route-mixin', 'garage/helpers/permission-utils'], function (exports, _restrictedRouteMixin, _permissionUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_restrictedRouteMixin.default, {
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        restrictions: [_permissionUtils.roleNames.admin, _permissionUtils.roleNames.super],

        viewData: Ember.Object.create({
            selected: null
        }),

        model: function model() {
            this.set('viewData.selected', 'users');

            return this.get('viewData');
        },


        actions: {}
    });
});